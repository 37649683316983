import { Injectable } from '@angular/core'
import { Event } from 'common/models/event'
import { EventListResponse } from 'common/models/event-list-response'
import { RequestService } from 'common/services/request.service'

@Injectable({ providedIn: 'root' })
export class EventsService {
  constructor(private requestService: RequestService) {}

  getActiveEventsList() {
    return this.requestService.post<unknown, EventListResponse>('/api/events/active', {}, {})
  }

  getArchiveEventsList(page: number, page_size: number) {
    return this.requestService.post<unknown, EventListResponse>(
      '/api/events/archive',
      {},
      { params: { page, page_size } }
    )
  }

  getEventItem(id: number) {
    return this.requestService.post<unknown, Event>(`/api/events/${id}`, {})
  }
}
