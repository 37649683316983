import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { TopDonorsResponse } from '../../../../../common/models/top-donors-response'

export const loadTopDonors = createActionGroup({
  source: 'Donors rating table items load',
  events: {
    start: emptyProps(),
    success: props<TopDonorsResponse>(),
    error: props<{ error: string }>()
  }
})

export const topGradesAllErrors = [loadTopDonors.error]
