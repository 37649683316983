import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { AddressService } from '../api/address.service'
import { addAddress, deleteAddress, getAddresses, setSelectedAddress, updateAddress } from './saved-addresses.actions'
import { cartSaveAddress } from 'client/src/store/checkout/cart/cart.actions'

@Injectable()
export class SavedAddressesEffects {
  constructor(private actions$: Actions, private addressService: AddressService) {}

  getAddresses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAddresses.start),
      mergeMap(() =>
        this.addressService.getAddresses().pipe(
          map(resp => getAddresses.success(resp)),
          catchError(error => of(getAddresses.error({ error: String(error) })))
        )
      )
    )
  )

  addAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addAddress.start),
      mergeMap(({ address }) =>
        this.addressService.add(address).pipe(
          map(resp => addAddress.success(resp)),
          catchError(error => of(addAddress.error({ error: String(error) })))
        )
      )
    )
  )

  deleteAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAddress.start),
      mergeMap(({ id }) =>
        this.addressService.delete(id).pipe(
          map(resp => deleteAddress.success(resp)),
          catchError(error => of(deleteAddress.error({ error: String(error) })))
        )
      )
    )
  )

  updateAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAddress.start),
      mergeMap(({ address, id }) =>
        this.addressService.update(id, address).pipe(
          map(resp => updateAddress.success(resp)),
          catchError(error => of(updateAddress.error({ error: String(error) })))
        )
      )
    )
  )

  selectAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSelectedAddress),
      mergeMap(({ address }) => of(cartSaveAddress({ data: address })))
    )
  )
}
