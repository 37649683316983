import { createAction, createActionGroup, props } from '@ngrx/store'
import { Order } from 'common/models/order'
import { PayOrderRequestV2 } from 'common/models/pay-order-request-v2'

export const payOrder = createActionGroup({
  source: '[Pay] pay',
  events: {
    start: props<{ req: PayOrderRequestV2 }>(),
    success: props<{ paymentOrder: Order }>(),
    error: props<{ error: string }>()
  }
})

export const clearCreatedOrder = createAction('[Checkout outlet] clear order')
export const isCurrencyForbiddenToChange = createAction(
  '[Checkout outlet] Is Currency Forbidden To Change',
  props<{ isForbidden: boolean }>()
)

export const checkPaymentStatus = createActionGroup({
  source: 'Check payment status',
  events: {
    start: props<{ orderId: string }>(),
    success: props<{ status: Order }>(),
    error: props<{ error: string }>()
  }
})

export const setBonusAmountError = createAction(
  '[Checkout outlet] set bonus amount error',
  props<{ isError: boolean }>()
)

export const setBonusValues = createAction(
  '[Checkout outlet] set bonus values',
  props<{ bonus: number | null; gift: number | null }>()
)

export const clearBonusValues = createAction('[Checkout outlet] clear bonus values')

export const allErrors = [checkPaymentStatus.error, payOrder.error]
