import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { setLocalStorageStorageDeferralDate } from './downgrade.helper'
import { FormsModule } from '@angular/forms'
import { ButtonComponent } from '../../../shared/ui/button/button.component'

@Component({
  selector: 'app-downgrade',
  standalone: true,
  imports: [CommonModule, MatDialogModule, FormsModule, ButtonComponent],
  templateUrl: 'downgrade.component.html',
  styleUrls: ['downgrade.component.scss'],
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DowngradeComponent {
  hideNoticeCheckbox = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { userId: number },
    public dialogRef: MatDialogRef<DowngradeComponent>
  ) {}

  close() {
    if (this.hideNoticeCheckbox) {
      setLocalStorageStorageDeferralDate(this.data.userId)
    }
    this.dialogRef.close()
  }

  onHideNoticeClick(evt: Event) {
    this.hideNoticeCheckbox = (evt.target as HTMLInputElement).checked
  }
}
