import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { EwaOffice } from '../../../../../common/models/ewa-office'

export const getContactsData = createActionGroup({
  source: '[Contacts] Get Contacts Data',
  events: {
    start: emptyProps(),
    success: props<{ contactsData: EwaOffice[] }>(),
    error: props<{ error: string }>()
  }
})
