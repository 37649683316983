<div class="cookie-consent-wrapper" *ngIf="!getIsConsented()" @cookieAnimation>
  <app-wrapper>
    <app-flex fd="row" ai="center" jc="space-between">
      <app-flex gap="5px" fd="column" ai="flex-start" jc="flex-start">
        <p class="cookies-consent-title">{{ translations.this_website_is_using_cookie | translate }}</p>
        <p class="cookies-consent-messge">
          {{ translations.by_continuing_to_use_our_site_you_consent_to_our_use_of_cookies | translate }}
          {{ translations.learn_more_about | translate }}
          <a routerLink="/procedures/consent-personal-data-agreement" class="underline">{{
            translations.personal_data_company_policy | translate
          }}</a>
        </p>
      </app-flex>
      <button app-button color="black" variant="filled" (click)="consent()">
        {{ translations.ok | translate }}
      </button>
    </app-flex>
  </app-wrapper>
</div>
