import { createFeatureSelector, createSelector } from '@ngrx/store'
import { featureKey, State } from './config.reducer'
import { Currency } from 'common/models/currency'
import { defaultCurrencyIsoCode } from '../../../../common/utils/currency/default-currency'
import { toExtendedCurrency } from '../../../../common/utils/currency/rates'

export const selectConfigFeature = createFeatureSelector<State>(featureKey)

export const selectCurrencies = createSelector(selectConfigFeature, s => s.currencies)

export const selectCountries = createSelector(selectConfigFeature, c => c.countries)

export const selectConfig = createSelector(selectConfigFeature, s => s.config)

export type ExtendedCurrency = Currency & { rateToBonusProceeded?: number; rateToCommodityProceeded?: number }

export const selectCurrenciesWithProceededBonusRates = createSelector(
  selectCurrencies,
  selectConfig,
  toExtendedCurrency
)

export const selectCurrency = createSelector(
  selectCurrenciesWithProceededBonusRates,
  (currencies): ExtendedCurrency | undefined => currencies?.[defaultCurrencyIsoCode()]
)

export const selectCurrencyFromIsoCode = (iso_code: string | undefined) =>
  createSelector(
    selectCurrenciesWithProceededBonusRates,
    (currencies): ExtendedCurrency | undefined => currencies?.[iso_code || defaultCurrencyIsoCode()]
  )

/**
 * Коллбеки для перевода из бонусов в валюту и обратно. Работают с бонусами
 * и валютами * 100 (только такие в коде и надо использовать, делить на 100
 * надо только перед отображением)
 *
 * Предполагается, что юзер будет работать всегда с одной валютой, поэтому
 * в возвращаемые функции можно не передавать второй аргумент (он просто на всякий случай).
 *
 * В будущем предполагается, что валюта (currencyIsoCode) будет браться из стора,
 * а пока что валюта - рубль.
 */
export const selectCurrenciesConversions = createSelector(selectCurrenciesWithProceededBonusRates, currencies => {
  return {
    // здесь ок делать проверки через &&, не замечая различий между 0 и undefined,
    // так как 0 после умножения на что угодно остается нулем
    bonusToCurrency: (
      bonusValue: number | undefined | null,
      currencyIsoCode: string | null = defaultCurrencyIsoCode()
    ) => {
      const currency: ExtendedCurrency = currencies?.[currencyIsoCode ?? defaultCurrencyIsoCode()]
      return bonusValue && currency?.rateToBonusProceeded && Math.round(bonusValue * currency.rateToBonusProceeded)
    },
    commodityToCurrency: (
      commodityValue: number | undefined | null,
      currencyIsoCode: string | null = defaultCurrencyIsoCode()
    ) => {
      const currency: ExtendedCurrency = currencies?.[currencyIsoCode ?? defaultCurrencyIsoCode()]
      return (
        commodityValue &&
        currency?.rateToCommodityProceeded &&
        Math.round(commodityValue * currency.rateToCommodityProceeded)
      )
    },
    currencyToBonus: (
      currencyValue: number | undefined | null,
      currencyIsoCode: string | null = defaultCurrencyIsoCode()
    ) => {
      const currency: ExtendedCurrency = currencies?.[currencyIsoCode ?? defaultCurrencyIsoCode()]
      return (
        currencyValue && currency?.rateToBonusProceeded && Math.round(currencyValue / currency.rateToBonusProceeded)
      )
    },
    currencyToCommodity: (
      currencyValue: number | undefined | null,
      currencyIsoCode: string | null = defaultCurrencyIsoCode()
    ) => {
      const currency: ExtendedCurrency = currencies?.[currencyIsoCode ?? defaultCurrencyIsoCode()]
      return (
        currencyValue &&
        currency?.rateToCommodityProceeded &&
        Math.round(currencyValue / currency.rateToCommodityProceeded)
      )
    },
    currencies: currencies
  }
})
