import { createActionGroup, props } from '@ngrx/store'
import { TicketWithMessages } from '../api/ticket.service'
import { TicketWithMessage } from '../../../../../../../common/models/ticket-with-message'
import { Ticket } from '../../../../../../../common/models/ticket'

export const loadTicketWithMessages = createActionGroup({
  source: 'Ticket page get ticket with messages',
  events: {
    start: props<{ id: number }>(),
    success: props<{ ticketWithMessage: TicketWithMessages }>(),
    error: props<{ error: string }>()
  }
})

export const sendMessage = createActionGroup({
  source: 'Ticket page send message',
  events: {
    start: props<{ message: { message: string; files: File[] }; ticketId: number }>(),
    success: props<{ ticket: TicketWithMessage }>(),
    error: props<{ error: string }>()
  }
})

export const updateTicketStatus = createActionGroup({
  source: 'Ticket page update ticket status',
  events: {
    start: props<{ ticket: Ticket }>(),
    success: props<{ ticket: Ticket }>(),
    error: props<{ error: string }>()
  }
})

export const allErrors = [loadTicketWithMessages.error, sendMessage.error, updateTicketStatus.error]
