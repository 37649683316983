import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { DpdService } from './dpd.service'
import { dpdLoad, dpdLoadError, dpdLoadSuccess } from './dpd.actions'
import { catchError, map, mergeMap, of } from 'rxjs'

@Injectable()
export class DpdEffects {
  constructor(private actions$: Actions, private dpdService: DpdService) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dpdLoad),
      mergeMap(({ city_id }) =>
        this.dpdService.load(city_id).pipe(
          map(data => dpdLoadSuccess({ data })),
          catchError(error => of(dpdLoadError({ error })))
        )
      )
    )
  )
}
