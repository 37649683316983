import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Clipboard } from '@angular/cdk/clipboard'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatRippleModule } from '@angular/material/core'

type Timer = ReturnType<typeof setTimeout>

@Component({
  selector: 'app-copy-btn',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatRippleModule],
  templateUrl: './copy-btn.component.html',
  styleUrls: ['./copy-btn.component.scss']
})
export class CopyBtnComponent implements OnDestroy {
  @Input() valueToCopy: string | null | undefined | number = ''
  @Input() color: 'gray' | 'white' | 'pink' = 'pink'

  copied = false
  private _timer: Timer | undefined

  constructor(private _clipboard: Clipboard, private _changeDetector: ChangeDetectorRef) {}

  copyValue() {
    if (this.valueToCopy !== undefined && this.valueToCopy !== null) {
      this._clipboard.copy(String(this.valueToCopy))
      this._handleTimer()
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this._timer)
  }

  private _handleTimer() {
    this.copied = true
    this._timer = setTimeout(() => {
      this.copied = false
      this._changeDetector.markForCheck()
    }, 1000)
  }
}
