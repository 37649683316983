import { Inject, Injectable } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { Constants } from '../../../../common/utils/constants/constants'

@Injectable({
  providedIn: 'root'
})
export class DocumentScrollService {
  constructor(@Inject(DOCUMENT) private _document: Document) {}

  disable(): void {
    this._document.documentElement.classList.add(Constants.DOCUMENT_CLASS_HIDE_SCROLL)
  }

  enable(): void {
    this._document.documentElement.classList.remove(Constants.DOCUMENT_CLASS_HIDE_SCROLL)
  }
}
