import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { TicketsTableService } from '../api/tickets-table.service'
import { loadTickets } from './tickets-table.actions'

@Injectable()
export class TicketsTableEffects {
  constructor(private actions$: Actions, private ticketsTableService: TicketsTableService) {}

  loadTickets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTickets.start),
      mergeMap(({ pagination }) =>
        this.ticketsTableService.getTickets(pagination).pipe(
          map(response => loadTickets.success(response)),
          catchError(error => of(loadTickets.error({ error: String(error) })))
        )
      )
    )
  )
}
