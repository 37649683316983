import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { EventsService } from '../../api/events.service'
import { getEventItem } from './events.actions'

@Injectable()
export class EventItemEffects {
  getEventItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEventItem.start),
      mergeMap(({ id }) =>
        this.eventsService.getEventItem(id).pipe(
          map(event => getEventItem.success({ event })),
          catchError(error => of(getEventItem.error({ error })))
        )
      )
    )
  )

  constructor(private actions$: Actions, private eventsService: EventsService) {}
}
