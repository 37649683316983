import { createAction, createActionGroup, props } from '@ngrx/store'
import { errorProps } from '../../../../../../common/utils/store/types'
import { Region } from '../../../../../../common/models/region'

export const regionLoad = createActionGroup({
  source: '[Region] Load',
  events: {
    start: props<{ countryId: number; regionName: string }>(),
    success: props<{ data: Region[] }>(),
    error: errorProps
  }
})

export const regionClear = createAction('[Region] Clear')

export const allErrors = [regionLoad.error]
