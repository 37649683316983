import { createActionGroup, props } from '@ngrx/store'
import { RegRequest } from 'common/models/reg-request'

export const registerPerson = createActionGroup({
  source: 'Register person',
  events: {
    start: props<{ data: RegRequest; who: 'client' | 'partner' }>(),
    success: props<{ data: RegRequest; who: 'client' | 'partner' }>(),
    error: props<{ error: string }>()
  }
})

export const allErrors = [registerPerson.error]
