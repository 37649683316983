import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { TopService } from '../api/top.service'
import { loadTopGrades } from './top.actions'

@Injectable()
export class TopEffects {
  constructor(private actions$: Actions, private topService: TopService) {}

  loadMinions = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTopGrades.start),
      mergeMap(() =>
        this.topService.getTopGrades().pipe(
          map(response => loadTopGrades.success(response)),
          catchError(error => of(loadTopGrades.error({ error: String(error) })))
        )
      )
    )
  )
}
