import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { FormBuilder, FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { ButtonComponent } from 'client/src/shared/ui/button/button.component'
import { DialogBodyComponent } from 'client/src/shared/ui/dialog/dialog-body/dialog-body.component'
import { DialogFooterComponent } from 'client/src/shared/ui/dialog/dialog-footer/dialog-footer.component'
import { DialogHeaderComponent } from 'client/src/shared/ui/dialog/dialog-header/dialog-header.component'
import { DialogLogoHeaderComponent } from 'client/src/shared/ui/dialog/dialog-logo-header/dialog-logo-header.component'
import { FlexComponent } from 'client/src/shared/ui/flex/flex.component'
import { FormFieldComponent } from 'client/src/shared/ui/form/form-field/form-field.component'
import { LinkComponent } from 'client/src/shared/ui/link/link.component'
import { TabsComponent } from 'client/src/shared/ui/tabs/tabs.component'
import { RegRequest } from 'common/models/reg-request'
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask'
import { RouterModule } from '@angular/router'
import { InternationalPhoneInputComponent } from 'client/src/shared/components/international-phone-input/international-phone-input.component'
import { NotifyService } from 'client/src/app/services/notify.service'
import { CountrySearchComponent } from '../../geo-search/country-search/country-search.component'
import { selectCountriesAll } from '../../geo-search/country-search/model/countries.selectors'
import { AppState } from '../../../store/state'
import { Store } from '@ngrx/store'
import { countriesClear, countriesLoad } from '../../geo-search/country-search/model/countries.actions'
import { RegionSearchComponent } from '../../geo-search/region-search/region-search.component'
import { RegistrationForm, userDataFromRegistrationForm } from './registration-form.utils'
import { CitySearchComponent } from '../../geo-search/city-search/city-search.component'
import { SexTypeEntries } from 'common/utils/minion/sex-type-list'
import { MatSelectModule } from '@angular/material/select'

@Component({
  selector: 'app-registration-modal',
  standalone: true,
  templateUrl: './registration-modal.component.html',
  styleUrls: ['./registration-modal.component.scss'],
  providers: [provideNgxMask()],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatFormFieldModule,
    ButtonComponent,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    DialogHeaderComponent,
    FormFieldComponent,
    DialogFooterComponent,
    DialogBodyComponent,
    DialogLogoHeaderComponent,
    MatDialogModule,
    TabsComponent,
    FlexComponent,
    MatCheckboxModule,
    LinkComponent,
    MatDatepickerModule,
    NgxMaskDirective,
    RouterModule,
    InternationalPhoneInputComponent,
    CountrySearchComponent,
    RegionSearchComponent,
    CitySearchComponent,
    MatSelectModule
  ]
})
export class RegistrationModalComponent implements OnInit, OnDestroy {
  constructor(private fb: FormBuilder, private notifyService: NotifyService, private store: Store<AppState>) {}

  form: RegistrationForm = {}

  agreements = this.fb.group({
    processingData: [false],
    marketingMessages: [false]
  })

  selectedOption: string | null = null

  countries$ = this.store.select(selectCountriesAll)

  sexTypeOptions = SexTypeEntries

  @Input() loading?: boolean

  @Input() userType?: string

  @Input() showUserCategory = true

  @Input() buttonText = 'ЗАРЕГИСТРИРОВАТЬ'

  @Input() titleText = 'РЕГИСТРАЦИЯ ПОЛЬЗОВАТЕЛЯ'

  @Input() partnerInfo?: { name: string; visibleId: string }

  @Output() closeDialog = new EventEmitter<void>()

  @Output() formSubmit = new EventEmitter<{ data: RegRequest; who: 'client' | 'partner' | null }>()

  is_info_accept: boolean = false

  ngOnInit() {
    if (this.showUserCategory) {
      this.selectedOption = 'Партнер'
    }
    this.store.dispatch(countriesLoad.start({ isDelivery: false }))
  }

  ngOnDestroy() {
    this.store.dispatch(countriesClear())
  }

  handleClickAcceptInfo(): void {
    this.is_info_accept = true
  }

  handleCountryChange(): void {
    this.form.region = undefined
    this.form.regionName = undefined
    this.form.city = undefined
    this.form.cityName = undefined
  }

  handleRegionChange(): void {
    this.form.city = undefined
    this.form.cityName = undefined
  }

  handleSubmit(ngForm: NgForm) {
    if (ngForm.invalid || this.agreementsInvalid) {
      return
    }

    const dateOfBirth = this.form.birthday!

    if (Math.abs(dateOfBirth.diffNow().shiftTo('year').years) < 18) {
      this.notifyService.error('Пользователь должен быть старше 18 лет')
      return
    }

    let who: 'client' | 'partner' | null = null
    if (this.selectedOption === 'Партнер') {
      who = 'partner'
    } else if (this.selectedOption === 'Клиент') {
      who = 'client'
    }

    this.formSubmit.emit({
      data: {
        user_data: userDataFromRegistrationForm(this.form),
        consent_advertising: Boolean(this.agreements.get('marketingMessages')?.value),
        login: (this.form.login ?? '').trim()
      },
      who
    })
  }

  get agreementsInvalid() {
    const agreements = this.agreements.value
    return !agreements.processingData
  }
}
