import { Injectable } from '@angular/core'
import { RequestService } from '../../../../../../../common/services/request.service'
import { Observable } from 'rxjs'
import { KazpostOffice } from '../../../../../../../common/models/kazpost-office'

@Injectable({
  providedIn: 'root'
})
export class KazpostService {
  constructor(private request: RequestService) {}

  load(city_id: number): Observable<KazpostOffice[]> {
    return this.request.post('/api/delivery/kazpost_offices', undefined, {
      params: {
        city_id
      }
    })
  }
}
