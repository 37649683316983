import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Metrika } from 'ng-yandex-metrika'
import { environment } from '../environments/environment'
import { filter, map, mergeMap } from 'rxjs'
import { SeoService } from './services/seo.service'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { LocalStorageService } from 'common/services/ls.service'
import { DOCUMENT } from '@angular/common'
import { Language } from 'common/utils/constants/language'
import * as moment from 'moment'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { AlertService } from '@shared/components/alert/alert.service'

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AppComponent implements OnInit {
  language: Language | null = null

  constructor(
    private _metrika: Metrika,
    private _router: Router,
    private _seoService: SeoService,
    private _lang: TranslateService,
    private _ls: LocalStorageService,
    @Inject(DOCUMENT) private _document: Document,
    public alert: AlertService
  ) {}

  ngOnInit() {
    moment.locale('ru')

    let prevPath = this._router.url

    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this._router.routerState.root),
        map(route => {
          while (route.firstChild) route = route.firstChild
          return route
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
        untilDestroyed(this)
      )
      .subscribe(data => {
        this._seoService.updateSeo({
          titleTranslationKey: data['titleTranslationKey'],
          metaTags: data['metaTags'],
          metaProps: data['metaProps']
        })

        if (environment.production) {
          const newPath = this._router.url
          if (newPath !== prevPath) {
            this._metrika.hit(newPath, { referer: prevPath })
            prevPath = newPath
          }
        }
      })

    this._lang.use(
      this._ls.hasItem(this._ls.keys.lang)
        ? (this._ls.getItem(this._ls.keys.lang) as string)
        : this._lang.currentLang || this._lang.defaultLang
    )

    this._lang.onLangChange.pipe(untilDestroyed(this)).subscribe((change: LangChangeEvent) => {
      this._ls.setItem(this._ls.keys.lang, change.lang)

      if (this.language) {
        this._document.body.classList.toggle(this.language as unknown as string)
      }

      this.language = change.lang as Language

      this._document.body.classList.toggle(this.language as unknown as string)

      this._document.documentElement.lang = change.lang
    })
  }
}
