import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { TopPartnersResponse } from 'common/models/top-partners-response'

export const loadTopRecruiters = createActionGroup({
  source: 'Recruiters rating table items load',
  events: {
    start: emptyProps(),
    success: props<TopPartnersResponse>(),
    error: props<{ error: string }>()
  }
})

export const topRecruitersAllErrors = [loadTopRecruiters.error]
