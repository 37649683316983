import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion'
import { CommonModule } from '@angular/common'
import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core'
import { ControlValueAccessor, FormControl, NgControl, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete'
import { MatFormFieldControl } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { Store } from '@ngrx/store'
import { AppState } from 'client/src/store/state'
import { City } from 'common/models/city'
import { BehaviorSubject, Subject, combineLatestWith, debounce, map, startWith, takeUntil, timer } from 'rxjs'
import { ruCountryId } from '../../../shared/utils/constants/country'
import { noCitiesFound, selectCities } from 'common/store/geo-search/geo-search.selector'
import { loadCities, removeCities } from 'common/store/geo-search/geo-search.actions'

@Component({
  selector: 'app-city-search',
  standalone: true,
  imports: [CommonModule, MatAutocompleteModule, MatInputModule, ReactiveFormsModule],
  templateUrl: './city-search.component.html',
  styleUrls: ['./city-search.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: CitySearchComponent
    }
  ]
})
export class CitySearchComponent implements OnInit, OnDestroy, ControlValueAccessor, MatFormFieldControl<City> {
  constructor(
    private store: Store<AppState>,
    @Optional() @Self() public ngControl: NgControl // need this to implement MatFormFieldControl
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this
    }
  }

  // country, that is used to search cities
  // if none, Russia is used
  @Input() set countryId(countryId: number | null | undefined) {
    this.countryIdSubject$.next(countryId || ruCountryId)
  }

  @Input() set regionId(regionId: number | null | undefined) {
    this.regionIdSubject$.next(regionId || undefined)
  }

  private city?: City

  cities$ = this.store.select(selectCities)
  notFound$ = this.store.select(noCitiesFound)

  cityControl = new FormControl<City | string>('')

  isDisabled = false

  private searchSubject$ = new BehaviorSubject<string>('')
  private countryIdSubject$ = new BehaviorSubject<number>(ruCountryId)
  private regionIdSubject$ = new BehaviorSubject<number | undefined>(undefined)
  private ngDestroy$ = new Subject<void>()

  ngOnInit() {
    this.registerSearchSubject()
    this.handleControlChange()
  }

  ngOnDestroy(): void {
    this.store.dispatch(removeCities())
    this.searchSubject$.complete()
    this.ngDestroy$.next()
    this.ngDestroy$.complete()
    this.stateChanges.complete()
  }

  displayFn(user: City): string {
    return user && user.name ? user.name : ''
  }

  private handleControlChange() {
    this.cityControl.valueChanges
      .pipe(takeUntil(this.ngDestroy$))
      .pipe(
        startWith(''),
        map(value => {
          if (typeof value === 'string') {
            this.searchSubject$.next(value)
          }
        })
      )
      .subscribe()
  }

  public handleSelect(event: MatAutocompleteSelectedEvent) {
    this.city = event.option.value as City
    this.change(this.city)
  }

  private registerSearchSubject() {
    this.searchSubject$
      .pipe(
        debounce(() => timer(500)),
        combineLatestWith(this.countryIdSubject$, this.regionIdSubject$)
      )
      .subscribe(([name, countryId, regionId]) => {
        this.store.dispatch(loadCities.start({ name, countryId, regionId }))
      })
  }

  handleBlur() {
    // это нужно, чтобы откатить изменения, если юзер стер часть названия города, а потом не выбрал новый.
    this.cityControl.setValue(this.city || null, { emitEvent: false })
    this.touched()
    this.onFocusOut()
  }

  // MatFormFieldControl fields

  @ViewChild('internalInput') internalInput?: ElementRef<HTMLInputElement>

  @Input()
  set value(city: City | null) {
    this.writeValue(city)
  }

  get value(): City | null {
    return this.city || null
  }

  stateChanges = new Subject<void>()

  static nextId = 0

  @HostBinding('id')
  id = `app-city-search-${CitySearchComponent.nextId++}`

  @Input() set placeholder(plh: string) {
    this._placeholder = plh
    this.stateChanges.next()
  }

  get placeholder(): string {
    return this._placeholder || ''
  }

  private _placeholder: string | undefined

  // that's just a copy and paste from https://material.angular.io/guide/creating-a-custom-form-field-control
  onFocusIn() {
    if (!this.focused) {
      this.focused = true
      this.stateChanges.next()
    }
  }

  // called inside blur, cos blur usually indicates that whole input loses focus
  // usage of (focusout) is not desirable, cos we have autocomplete, which also can take focus
  onFocusOut() {
    this.focused = false
    this.stateChanges.next()
  }

  focused = false

  get empty(): boolean {
    return !this.city
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty
  }

  @Input()
  get required(): boolean {
    return this._required
  }

  set required(req: BooleanInput) {
    this._required = coerceBooleanProperty(req)
    this.stateChanges.next()
  }

  private _required = false

  @Input()
  get disabled(): boolean {
    return this.isDisabled
  }

  set disabled(value: BooleanInput) {
    this.setDisabledState(coerceBooleanProperty(value))
  }

  get errorState(): boolean {
    return (this.ngControl.invalid && this.ngControl.touched) || false
  }

  controlType = 'app-city-search'

  setDescribedByIds(): void {
    // not implemented, cos I don't really know why it should
    // control value accessor never cares about such things
    return
  }

  onContainerClick() {
    this.internalInput?.nativeElement?.focus()
  }

  // ControlValueAccessor methods
  onChange?: (city: City | undefined) => void
  onTouched?: () => void

  writeValue(city: City | undefined | null): void {
    this.city = city || undefined
    this.cityControl.setValue(this.city || null, { emitEvent: false })
    this.stateChanges.next()
  }

  registerOnChange(fn: (city: City | undefined) => void): void {
    this.onChange = fn
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled
    this.stateChanges.next()
  }

  change(value: City | undefined): void {
    this.onChange?.(value)
    this.touched()
    this.stateChanges.next()
  }

  touched(): void {
    this.onTouched?.()
  }
}
