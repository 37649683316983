import { createActionGroup, props } from '@ngrx/store'
import { AccrualsGroupVolumesHistoryRequest } from 'common/models/accruals-group-volumes-history-request'
import { AccrualsGroupVolumesListResponse } from 'common/models/accruals-group-volumes-list-response'

export const getGroupAccruals = createActionGroup({
  source: 'Group volume page get accurals',
  events: {
    start: props<{
      pagination: { page: number }
      dateFilters: AccrualsGroupVolumesHistoryRequest | undefined
    }>(),
    success: props<AccrualsGroupVolumesListResponse>(),
    error: props<{ error: string }>()
  }
})

export const allErrors = [getGroupAccruals.error]
