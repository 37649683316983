import { Injectable } from '@angular/core'
import { CreateTicketRequest } from 'common/models/create-ticket-request'
import { Ticket } from 'common/models/ticket'
import { RequestService } from 'common/services/request.service'
import { TicketRequestType } from '../../../../../../../common/models/ticket-request-type'

export interface TicketData {
  ticketType: TicketRequestType
  title: string
  message: string
  attachments: { name: string; base64: string }[]
}

@Injectable({ providedIn: 'root' })
export class CreateTicketService {
  constructor(private requestService: RequestService) {}

  sendTicket({ ticketType, title, attachments, message }: TicketData) {
    return this.requestService.post<CreateTicketRequest, Ticket>('api/tickets/create', {
      request_type: ticketType,
      title,
      message: {
        attachments: attachments.map(a => ({ data: a.base64, name: a.name })),
        message
      }
    })
  }
}
