import { createActionGroup, props } from '@ngrx/store'
import { TicketsListResponse } from 'common/models/tickets-list-response'

export const loadTickets = createActionGroup({
  source: 'Tickets table get tickets',
  events: {
    start: props<{
      pagination: { page: number }
    }>(),
    success: props<TicketsListResponse>(),
    error: props<{ error: string }>()
  }
})

export const allErrors = [loadTickets.error]
