import { Injectable } from '@angular/core'
import { ProductWithItems } from 'common/models/product-with-items'
import { RequestService } from 'common/services/request.service'
import { Observable, forkJoin } from 'rxjs'
import { defaultCountryCode } from 'common/utils/currency/default-currency'
import { SuccessResponse } from 'common/models/success-response'
import { Category } from 'common/models/category'

@Injectable({
  providedIn: 'root'
})
export class ShopService {
  constructor(private readonly _requestService: RequestService) {}

  getProducts() {
    const countryId = defaultCountryCode()
    return this._requestService.post<Record<string, never>, ProductWithItems[]>(
      'api/products/list?country_id=' + countryId,
      {}
    )
  }

  getCategories() {
    return this._requestService.get<Category[]>('api//products/v2/categories')
  }

  getProductsAndCategories() {
    return forkJoin([this.getProducts(), this.getCategories()])
  }

  getRecommended(id: number): Observable<ProductWithItems[]> {
    return this._requestService.get(`api/products/recommended_products/${id}`)
  }

  getSingleProduct(slug: string, stock_id: number): Observable<ProductWithItems> {
    return this._requestService.get<ProductWithItems>(`api/products/${slug}?stock_id=` + stock_id, {})
  }

  addFavoriteProduct(productId: number): Observable<SuccessResponse> {
    return this._requestService.post<unknown, SuccessResponse>('api/products/favourites', { productId: productId })
  }

  deleteFavoriteProduct(productId: number): Observable<SuccessResponse> {
    return this._requestService.delete<unknown, SuccessResponse>(`api/products/favourites/${productId}`, {})
  }
}
