import { createActionGroup, props } from '@ngrx/store'
import { AccrualsPersonalVolumesListResponse } from 'common/models/accruals-personal-volumes-list-response'

export const getPersonalAccruals = createActionGroup({
  source: 'Personal volume page get accurals',
  events: {
    start: props<{
      pagination: { page: number }
    }>(),
    success: props<AccrualsPersonalVolumesListResponse>(),
    error: props<{ error: string }>()
  }
})

export const allErrors = [getPersonalAccruals.error]
