import { createReducer, on } from '@ngrx/store'
import { loadTicketWithMessages, sendMessage, updateTicketStatus } from './ticket.actions'
import { TicketWithMessages } from '../api/ticket.service'

export const featureKey = 'Ticket page'

export interface State {
  loading: boolean
  messageSending: boolean
  data: TicketWithMessages | null
}

export const initialState: State = {
  loading: false,
  data: null,
  messageSending: false
}

export const reducer = createReducer(
  initialState,
  on(loadTicketWithMessages.start, state => {
    return { ...state, loading: true }
  }),
  on(loadTicketWithMessages.success, (state, response) => {
    return { ...state, loading: false, data: response.ticketWithMessage }
  }),
  on(loadTicketWithMessages.error, state => {
    return { ...state, loading: false }
  }),

  on(sendMessage.start, state => {
    return { ...state, messageSending: true }
  }),
  on(sendMessage.success, (state, { ticket }) => {
    return {
      ...state,
      messageSending: false,
      data: state.data
        ? {
            ...state.data,
            ticket: ticket.ticket || state.data.ticket,
            messages: ticket.message ? [...state.data.messages, ticket.message] : state.data.messages
          }
        : null
    }
  }),
  on(sendMessage.error, state => {
    return { ...state, messageSending: false }
  }),
  on(updateTicketStatus.success, (state, { ticket }) => {
    return {
      ...state,
      data: state.data
        ? {
            ...state.data,
            ticket
          }
        : null
    }
  })
)
