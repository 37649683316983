import { Injectable } from '@angular/core'
import { RequestService } from '../../../../../common/services/request.service'
import { ReviewsListResponse } from '../../../../../common/models/reviews-list-response'
import { CreateReviewRequest } from '../../../../../common/models/create-review-request'
import { Observable } from 'rxjs'
import { Review } from '../../../../../common/models/review'

@Injectable({
  providedIn: 'root'
})
export class ReviewService {
  constructor(private requestService: RequestService) {}

  load(productId: number, page: number, pageSize: number): Observable<ReviewsListResponse> {
    return this.requestService.post<void, ReviewsListResponse>(`/api/reviews/by_product/${productId}`, undefined, {
      params: { page, page_size: pageSize }
    })
  }

  add(req: CreateReviewRequest): Observable<Review> {
    return this.requestService.post('/api/reviews/create', req)
  }
}
