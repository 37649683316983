import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { ContactPageService } from '../services/geography-page.service'
import { getContactsData } from './geography-page.actions'
import { catchError, map, mergeMap, of } from 'rxjs'

@Injectable()
export class ContactPageEffects {
  getContactsData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getContactsData.start),
      mergeMap(() =>
        this._contactService.getContactsData().pipe(
          map(contactsData => getContactsData.success({ contactsData })),
          catchError(error => of(getContactsData.error({ error: String(error) })))
        )
      )
    )
  )

  constructor(private _actions$: Actions, private _contactService: ContactPageService) {}
}
