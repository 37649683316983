import { Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { CartApiService } from '../../../features/cart/services/cart-api.service'
import { catchError, EMPTY, map, mergeMap, of, switchMap, tap } from 'rxjs'
import { Address } from '../../../../../common/models/address'
import { UpdateDeliveryInCartRequest } from '../../../../../common/models/update-delivery-in-cart-request'
import { AppState } from '../../state'
import { Router } from '@angular/router'
import {
  addCartItem,
  addCartItemError,
  addCartItemSuccess,
  cartEstimateDelivery,
  cartEstimateDeliveryError,
  cartEstimateDeliverySuccess,
  cartSaveAddress,
  cartSaveCity,
  cartSaveCityError,
  cartSaveCitySuccess,
  cartSaveCountry,
  cartSaveDeliveryMethod,
  cartSaveDeliveryMethodError,
  cartSaveDeliveryMethodSuccess,
  cartSaveDeliveryOffice,
  cartSaveDpdDeliveryKind,
  cartSaveRegion,
  cartSaveRuPostCourierDeliveryKind,
  cartSaveRuPostToOfficeDeliveryKind,
  clearCart,
  deleteCartItem,
  deleteCartItemError,
  deleteCartItemSuccess,
  getCart,
  getCartError,
  getCartSuccess,
  updateCartItem,
  updateCartItemError,
  updateCartItemSuccess,
  updateManyCartItems,
  updateManyCartItemsError,
  updateManyCartItemsSuccess,
  updatePersonalInfo,
  updateStock,
  updateCurrency,
  changeCountryTest,
  changeCountrySuccessTest,
  approveGroupOrder,
  cartSaveDeliveryYandexInterval,
  updateTotalCurrencyPrice,
  getPaymentAcquirings
} from './cart.actions'
import {
  selectCartCity,
  selectCartCountry,
  selectCartDelivery,
  selectCartDeliveryMethod,
  selectCartIsInternational,
  selectCurrentYandexTimeDelivery
} from './cart.selectors'
import { selectDeliveryIsCourierValue } from '../delivery/delivery-is-courier/delivery-is-courier.selectors'
import { RupostInternationalDeliveryKind } from '../../../../../common/models/rupost-international-delivery-kind'
import { defaultCountryCode, defaultCurrencyIsoCode } from '../../../../../common/utils/currency/default-currency'
import { getProductsAndCategories } from '../../shop/shop.actions'
import { getStocks } from '../../../widgets/Stock/model'
import { DeliveryFormService } from '../../../features/delivery/components/delivery-form/delivery-form.service'
import { NotifyService } from '../../../app/services/notify.service'
import { PaymentAcquiringListResponse } from '../../../../../common/models/payment-acquiring-list-response'

@Injectable()
export class CartEffects {
  approveGroupOrder$ = createEffect(() =>
    this._actions$.pipe(
      ofType(approveGroupOrder.start),
      mergeMap(({ req }) =>
        this._cartService.approveGroupOrderRequest(req).pipe(
          map(cart => approveGroupOrder.success({ cart })),
          catchError(error => {
            this._notifyService.error(String(error))
            return of(approveGroupOrder.error({ error: String(error) }))
          })
        )
      ),
      tap(action => {
        if (action.type === approveGroupOrder.success.type) {
          this._router.navigate(['/checkout/delivery'])
        }
      })
    )
  )

  addCartItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(addCartItem),
      mergeMap(({ item }) =>
        this._cartService.addToCart(item.id ?? -1).pipe(
          mergeMap(cart => of(addCartItemSuccess({ cart }), updateTotalCurrencyPrice.start())),
          catchError(error => of(addCartItemError({ error: String(error) })))
        )
      )
    )
  )

  getCart$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getCart),
      mergeMap(() =>
        this._cartService.getCart().pipe(
          map(cart => getCartSuccess({ cart })),
          catchError(error => of(getCartError({ error: String(error) })))
        )
      )
    )
  )

  updateCartItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateCartItem),
      mergeMap(({ item }) =>
        this._cartService.updateCartItem(item).pipe(
          map(cart => updateCartItemSuccess({ cart })),
          catchError(error => of(updateCartItemError({ error: String(error) })))
        )
      )
    )
  )

  deleteCartItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(deleteCartItem),
      mergeMap(({ item }) =>
        this._cartService.deleteCartItem(item.id ?? -1).pipe(
          map(cart => deleteCartItemSuccess({ cart })),
          catchError(error => of(deleteCartItemError({ error: String(error) })))
        )
      )
    )
  )

  updateManyCartItems$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateManyCartItems),
      mergeMap(({ idAmountMap }) =>
        this._cartService.updateManyCartItems(idAmountMap).pipe(
          mergeMap(cart => of(updateManyCartItemsSuccess({ cart }), updateTotalCurrencyPrice.start())),
          catchError(e => of(updateManyCartItemsError({ error: String(e) })))
        )
      )
    )
  )

  estimateDeliveryCourier$ = createEffect(() =>
    this._actions$.pipe(
      ofType(cartSaveAddress),
      concatLatestFrom(() => [
        this._store.select(selectCartCountry),
        this._store.select(selectCartCity),
        this._store.select(selectCartDeliveryMethod),
        this._store.select(selectDeliveryIsCourierValue),
        this._store.select(selectCartIsInternational),
        this._store.select(selectCurrentYandexTimeDelivery)
      ]),
      mergeMap(([{ data }, country, city, method, isCourier, isInternational, yandexDeliveryInterval]) => {
        data = { ...data }
        if (data && country && city && method) {
          const address: Address = {
            ...data,
            id: 0,
            region_id: city.region_id,
            city_id: city.id,
            city: city.name,
            region: city.region,
            country: country.name,
            country_id: country.id,
            address_full: ''
          }
          const req: UpdateDeliveryInCartRequest = {}
          if (method?.method === 'Boxberry') {
            req.boxberry = {
              is_courier: isCourier,
              address
            }
          }
          if (method?.method === 'Fargo') {
            req.fargo = {
              is_courier: isCourier,
              address,
              country_id_to: country.id
            }
          }
          if (method?.method === 'KgPost') {
            req.kgpost = {
              is_courier: isCourier,
              address
            }
          }
          if (method?.method === 'Cdek') {
            req.cdek = {
              is_courier: isCourier,
              address,
              country_id_to: country.id
            }
          }
          if (method?.method === 'Europost') {
            req.europost = {
              is_courier: isCourier,
              address,
              country_id_to: country.id
            }
          }
          if (method?.method === 'Haypost') {
            req.haypost = {
              is_courier: isCourier,
              address,
              country_id_to: country.id
            }
          }
          if (method?.method === 'Kazpost') {
            req.kazpost = {
              oldPostcode: data.old_postal_code || '',
              postcode: data.postal_code || '',
              country_id_to: country.id
            }
          }
          if (method?.method === 'Yandex') {
            req.yandex = {
              is_courier: isCourier,
              address,
              country_id_to: country.id,
              interval: yandexDeliveryInterval
            }
          }
          if (method?.method === 'DPD') {
            req.dpd = {
              is_courier: isCourier,
              address,
              kind: 'DPDDeliveryKindOptimum',
              country_id_to: country.id
            }
          }
          if (method?.method === 'RuPost') {
            if (isInternational) {
              req.ru_post = isCourier
                ? {
                    is_courier: isCourier,
                    address,
                    international_kind: RupostInternationalDeliveryKind.RupostInternationalDeliveryEms,
                    country_id_to: country.id
                  }
                : {
                    is_courier: isCourier,
                    address,
                    international_kind: RupostInternationalDeliveryKind.RupostInternationalDeliveryClassic,
                    country_id_to: country.id
                  }
            } else {
              req.ru_post = {
                is_courier: isCourier,
                address,
                courier_kind: 'RupostCourierDeliveryStandard',
                country_id_to: country.id
              }
            }
          }
          // у Boxberry нет курьерской
          // у FivePost нет курьерской
          return of(cartEstimateDelivery({ data: req }))
        }
        return EMPTY
      })
    )
  )

  cartSaveDeliveryOffice$ = createEffect(() =>
    this._actions$.pipe(
      ofType(cartSaveDeliveryOffice),
      concatLatestFrom(() => [this._store.select(selectCartCity)]),
      mergeMap(([{ data }, city]) => {
        if (data && city && city.country_id !== undefined) {
          const req: UpdateDeliveryInCartRequest = {}
          if (data.method === 'Boxberry') {
            req.boxberry = {
              is_courier: false,
              office_code: data.office.code
            }
          }
          if (data.method === 'KgPost') {
            req.kgpost = {
              is_courier: false,
              office_id: String(data.office.id)
            }
          }
          if (data.method === 'Cdek') {
            req.cdek = {
              is_courier: false,
              office_code: data.office.code,
              country_id_to: city.country_id
            }
          }
          if (data.method === 'DPD') {
            req.dpd = {
              is_courier: false,
              office_code: data.office.code,
              kind: 'DPDDeliveryKindOptimum',
              country_id_to: city.country_id
            }
          }
          if (data.method === 'Europost') {
            req.europost = {
              is_courier: false,
              address: data.office.address,
              office_code: data.office.code,
              country_id_to: city.country_id,
              work_time: data.office.work_time || ''
            }
          }
          if (data.method === 'Fargo') {
            req.fargo = {
              is_courier: false,
              //address: data.office.address,
              country_id_to: city.country_id,
              code: data.office.code,
              work_time: data.office.work_time || ''
            }
          }
          if (data.method === 'Kazpost') {
            req.kazpost = {
              oldPostcode: data.office.address?.old_postal_code || '',
              postcode: data.office.postcode || '',
              country_id_to: city.country_id,
              work_time: data.office.work_time || ''
            }
          }
          if (data.method === 'RuPost') {
            req.ru_post = {
              is_courier: false,
              postal_code: data.office.address?.postal_code,
              to_office_kind: 'RupostOfficeDeliveryStandard',
              country_id_to: city.country_id
            }
          }
          if (data.method === 'FivePost') {
            req.five_post = {
              office_code: data.office.code
            }
          }
          return of(cartEstimateDelivery({ data: req }))
        }
        return EMPTY
      })
    )
  )

  estimateDeliveryYandexTimeInterval$ = createEffect(() =>
    this._actions$.pipe(
      ofType(cartSaveDeliveryYandexInterval),
      concatLatestFrom(() => [this._store.select(selectCartDelivery), this._store.select(selectCartCountry)]),
      mergeMap(([{ interval }, delivery, country]) => {
        if (delivery && delivery.method === 'Yandex' && delivery.delivery.is_courier && country) {
          return of(
            cartEstimateDelivery({
              data: {
                yandex: {
                  is_courier: true,
                  country_id_to: country.id,
                  address: delivery.delivery.address,
                  interval
                }
              }
            })
          )
        }
        return EMPTY
      })
    )
  )

  estimateDeliveryRuPostCourierKind$ = createEffect(() =>
    this._actions$.pipe(
      ofType(cartSaveRuPostCourierDeliveryKind),
      concatLatestFrom(() => [this._store.select(selectCartDelivery), this._store.select(selectCartCountry)]),
      mergeMap(([{ data }, delivery, country]) => {
        if (delivery && delivery.method === 'RuPost' && delivery.delivery.is_courier && country) {
          return of(
            cartEstimateDelivery({
              data: {
                ru_post: {
                  is_courier: true,
                  country_id_to: country.id,
                  address: delivery.delivery.address,
                  courier_kind: data
                }
              }
            })
          )
        }
        return EMPTY
      })
    )
  )

  estimateDeliveryRuPostToOfficeKind$ = createEffect(() =>
    this._actions$.pipe(
      ofType(cartSaveRuPostToOfficeDeliveryKind),
      concatLatestFrom(() => [this._store.select(selectCartDelivery), this._store.select(selectCartCountry)]),
      mergeMap(([{ data }, delivery, country]) => {
        if (delivery && delivery.method === 'RuPost' && !delivery.delivery.is_courier && country) {
          return of(
            cartEstimateDelivery({
              data: {
                ru_post: {
                  is_courier: false,
                  country_id_to: country.id,
                  postal_code: delivery.delivery?.office?.address?.postal_code,
                  to_office_kind: data
                }
              }
            })
          )
        }
        return EMPTY
      })
    )
  )

  estimateDeliveryDpdKind$ = createEffect(() =>
    this._actions$.pipe(
      ofType(cartSaveDpdDeliveryKind),
      concatLatestFrom(() => [this._store.select(selectCartDelivery), this._store.select(selectCartCountry)]),
      mergeMap(([{ data }, delivery, country]) => {
        if (delivery && delivery.method === 'DPD' && country) {
          return of(
            cartEstimateDelivery({
              data: {
                dpd: {
                  is_courier: Boolean(delivery.delivery.is_courier),
                  country_id_to: country.id,
                  address: delivery.delivery.address,
                  office_code: delivery.delivery.office?.code,
                  kind: data
                }
              }
            })
          )
        }
        return EMPTY
      })
    )
  )

  estimateDelivery$ = createEffect(() =>
    this._actions$.pipe(
      ofType(cartEstimateDelivery),
      mergeMap(({ data }) =>
        this._cartService.estimateDelivery(data).pipe(
          tap(data => {
            const deliveryTypes = []
            if (data.advance_costs?.length)
              for (const item of data.advance_costs) {
                if (item.rupost_to_office_kind && item.method === 'RuPost') {
                  deliveryTypes.push(item.rupost_to_office_kind)
                }
              }
            this._deliveryService.deliveryMethods$.next(deliveryTypes)
          }),
          mergeMap(cart => of(cartEstimateDeliverySuccess({ cart }), updateTotalCurrencyPrice.start())),
          catchError(error => of(cartEstimateDeliveryError({ error })))
        )
      )
    )
  )

  saveCity$ = createEffect(() =>
    this._actions$.pipe(
      ofType(cartSaveCity),
      mergeMap(({ data }) =>
        this._cartService.saveCity(data).pipe(
          map(cart => cartSaveCitySuccess({ cart })),
          catchError(error => of(cartSaveCityError({ error })))
        )
      )
    )
  )

  saveCountry$ = createEffect(() =>
    this._actions$.pipe(
      ofType(cartSaveCountry.start),
      mergeMap(({ data }) =>
        this._cartService.updateCountry(data).pipe(
          map(cart => cartSaveCountry.success({ cart })),
          catchError(error => of(cartSaveCountry.error({ error })))
        )
      )
    )
  )

  saveRegion$ = createEffect(() =>
    this._actions$.pipe(
      ofType(cartSaveRegion.start),
      mergeMap(({ data }) =>
        this._cartService.saveRegion(data).pipe(
          map(cart => cartSaveRegion.success({ cart })),
          catchError(error => of(cartSaveRegion.error({ error })))
        )
      )
    )
  )

  saveDeliveryMethod$ = createEffect(() =>
    this._actions$.pipe(
      ofType(cartSaveDeliveryMethod),
      mergeMap(({ method }) =>
        this._cartService
          .saveDeliveryMethod({
            delivery_method: method.method,
            group_order_id: method.groupOrderId
          })
          .pipe(
            mergeMap(cart => of(cartSaveDeliveryMethodSuccess({ cart, method }), updateTotalCurrencyPrice.start())),
            catchError(error => of(cartSaveDeliveryMethodError({ error })))
          )
      )
    )
  )

  clearCart$ = createEffect(() =>
    this._actions$.pipe(
      ofType(clearCart.start),
      mergeMap(() =>
        this._cartService.clearCart().pipe(
          mergeMap(cart => of(clearCart.success({ cart }), updateTotalCurrencyPrice.start())),
          catchError(error => of(clearCart.error({ error })))
        )
      )
    )
  )

  updatePersonalInfo$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updatePersonalInfo.start),
      mergeMap(({ info }) =>
        this._cartService.updatePersonalInfo(info).pipe(
          map(cart => updatePersonalInfo.success({ cart })),
          catchError(error => of(updatePersonalInfo.error({ error })))
        )
      )
    )
  )

  updateCartStock$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateStock.start),
      mergeMap(({ stockId }) =>
        this._cartService.updateStock(stockId).pipe(
          mergeMap(cart => {
            return of(updateStock.success({ cart }), updateTotalCurrencyPrice.start())
          }),
          catchError(error => of(updateStock.error({ error })))
        )
      )
    )
  )

  updateCartCurrency$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateCurrency.start),
      mergeMap(({ currencyId }) =>
        this._cartService.updateCurrency(currencyId).pipe(
          map(cart => updateCurrency.success({ cart })),
          catchError(error => of(updateStock.error({ error })))
        )
      )
    )
  )

  updateCartTotalPriceCurrency$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateTotalCurrencyPrice.start),
      mergeMap(() =>
        this._cartService.updateCartTotalPriceCurrency().pipe(
          map((response): any => updateTotalCurrencyPrice.success({ prices: response })),
          catchError(error => of(updateTotalCurrencyPrice.error({ error: error })))
        )
      )
    )
  )

  changeCountryTest$ = createEffect(() =>
    this._actions$.pipe(
      ofType(changeCountryTest),
      mergeMap(({ country }) =>
        this._cartService.changeCountryById(country).pipe(
          tap(cart => {
            const isoCodeInternal = cart?.currency_id || defaultCurrencyIsoCode()
            const countryId = String(cart?.user_country_id) || defaultCountryCode()

            localStorage.setItem('countryId', countryId)
            localStorage.setItem('currency', isoCodeInternal)
          }),
          mergeMap(() =>
            of(
              getCart(),
              getProductsAndCategories.start(),
              updateTotalCurrencyPrice.start(),
              // @ts-ignore
              getStocks.start({ countryId: localStorage.getItem('countryId') }),
              changeCountrySuccessTest()
            )
          ),
          catchError(error => of(getCartError({ error: String(error) })))
        )
      )
    )
  )

  payAcquirings$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getPaymentAcquirings.start),
      switchMap(params =>
        this._cartService.getPayAcquirings(params).pipe(
          map(({ payment_acquirings }: PaymentAcquiringListResponse) => {
            const acquirings = payment_acquirings || []

            return getPaymentAcquirings.success({ acquirings })
          }),
          catchError(error => of(getPaymentAcquirings.error({ error: error })))
        )
      )
    )
  )

  constructor(
    private _actions$: Actions,
    private _cartService: CartApiService,
    private _store: Store<AppState>,
    private _router: Router,
    private _deliveryService: DeliveryFormService,
    private _notifyService: NotifyService
  ) {}
}
