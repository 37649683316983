import { createReducer, on } from '@ngrx/store'
import { europostLoad, europostLoadError, europostLoadSuccess, europostRemove } from './europost.actions'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { EuropostOffice } from '../../../../../../../common/models/europost-office'

export const europostFeatureKey = 'europost'

export interface State extends EntityState<EuropostOffice> {
  isLoading: boolean
  isLoaded: boolean
}

const selectId = (v: EuropostOffice) => v.code as string

const sortById = (a: EuropostOffice, b: EuropostOffice) => a.code!.localeCompare(b.code!)

export const adapter = createEntityAdapter<EuropostOffice>({
  selectId,
  sortComparer: sortById
})

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  isLoaded: false
})

export const reducer = createReducer(
  initialState,
  on(europostLoad, state => ({ ...state, isLoading: true })),
  on(europostLoadSuccess, (state, { data }) => adapter.setAll(data, { ...state, isLoading: false, isLoaded: true })),
  on(europostLoadError, state => ({ ...state, isLoading: false })),
  on(europostRemove, () => initialState)
)
