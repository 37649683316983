import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { fivePostLoad, fivePostLoadError, fivePostLoadSuccess } from './five-post.actions'
import { catchError, map, mergeMap, of } from 'rxjs'
import { FivePostService } from './five-post.service'

@Injectable()
export class FivePostEffects {
  constructor(private actions$: Actions, private fivePostService: FivePostService) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fivePostLoad),
      mergeMap(({ city_id }) =>
        this.fivePostService.load(city_id).pipe(
          map(data => fivePostLoadSuccess({ data })),
          catchError(error => of(fivePostLoadError({ error })))
        )
      )
    )
  )
}
