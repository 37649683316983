import { createReducer, on } from '@ngrx/store'
import { getNewPromoData } from './new-promo.actions'

export const newPromoFeatureKey = 'newpromo'

export interface State {
  users: number
  tokens: []
}

export const initialState: State = {
  users: 0,
  tokens: []
}

export const reducer = createReducer(
  initialState,
  on(getNewPromoData.start, state => ({ ...state })),
  on(getNewPromoData.success, (state, { tokens }) => ({
    ...state,
    users: tokens.turkish_0524?.activations || 0,
    tokens: tokens.turkish_0524?.tokens || []
  }))
)
