import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { EventsService } from '../../api/events.service'
import { getArchiveEventsList } from './events.actions'

@Injectable()
export class ArchiveEventsEffects {
  getArchiveEventsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getArchiveEventsList.start),
      mergeMap(({ page, page_size }) =>
        this.eventsService.getArchiveEventsList(page, page_size).pipe(
          map(response => getArchiveEventsList.success({ response })),
          catchError(error => of(getArchiveEventsList.error({ error })))
        )
      )
    )
  )

  constructor(private actions$: Actions, private eventsService: EventsService) {}
}
