import { createActionGroup, emptyProps, props } from '@ngrx/store'

export const recoverPassword = createActionGroup({
  source: 'Recover password',
  events: {
    start: props<{ phone: string }>(),
    success: emptyProps(),
    error: props<{ error: string }>()
  }
})

export const allErrors = [recoverPassword.error]
