import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { CheckoutService } from 'client/src/features/checkout/services/checkout.service'
import { catchError, debounceTime, map, mergeMap, of, tap } from 'rxjs'
import { checkPaymentStatus, payOrder, setBonusValues } from './pay.actions'
import { Router } from '@angular/router'
import { loadBonusInfo } from '../../../widgets/checkout/bonus-info-after-order/model/bonus-info.actions'
import { getCart } from '../cart/cart.actions'
import { readonlyToken } from 'client/src/features/checkout/apply-referrer-token/model/apply-token.actions'

@Injectable()
export class PayEffects {
  payOrder$ = createEffect(() =>
    this._actions$.pipe(
      ofType(payOrder.start),
      mergeMap(({ req }) =>
        this._checkoutService.payOrder(req).pipe(
          map(paymentOrder => payOrder.success({ paymentOrder })),
          catchError(error => of(payOrder.error({ error }), readonlyToken({ s: false })))
        )
      )
    )
  )

  refreshCartAfterPay$ = createEffect(() =>
    this._actions$.pipe(
      ofType(payOrder.success),
      mergeMap(() => of(getCart()))
    )
  )

  redirectToPay$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(payOrder.success),
        tap(({ paymentOrder }) => {
          this._router.navigate(['/checkout/pay-order', paymentOrder.id])
        })
      ),
    { dispatch: false }
  )

  checkPayment$ = createEffect(() =>
    this._actions$.pipe(
      ofType(checkPaymentStatus.start),
      mergeMap(({ orderId }) =>
        this._checkoutService.checkPayment(orderId).pipe(
          map(status => checkPaymentStatus.success({ status })),
          catchError(error => of(checkPaymentStatus.error({ error: String(error) })))
        )
      )
    )
  )

  loadBonusInfo$ = createEffect(() =>
    this._actions$.pipe(
      ofType(setBonusValues),
      debounceTime(800),
      mergeMap(({ bonus, gift }) =>
        of(
          loadBonusInfo.start({
            req: {
              bonus_points_in_currency: bonus || 0,
              commodity_points_in_currency: gift || 0
            }
          })
        )
      )
    )
  )

  constructor(private _actions$: Actions, private _checkoutService: CheckoutService, private _router: Router) {}
}
