import { Pipe, PipeTransform } from '@angular/core'
import { DeliveryMethodData } from '../../../../../common/models/delivery-method-data'
import { AllUserDataResp } from '../../../../../common/models/all-user-data-resp'
import { DeliveryMethod } from '../../../../../common/models/delivery-method'
import { AppDeliveryMethodData } from '../lib/delivery-method-data'

/**
 * Костыль, добавляющий групповой заказ и международную ручную доставку к методам доставки
 */

export const addFakeDeliveryMethods = (
  methods: AppDeliveryMethodData[] | undefined | null,
  user: AllUserDataResp | undefined | null
): AppDeliveryMethodData[] => {
  const res = methods ? [...methods] : []
  if (user?.main_user_data?.can_fake_delivery) {
    res.push({
      method: DeliveryMethod.FakeDelivery,
      courierAvailable: true,
      isInternational: true,
      officeAvailable: true
    })
  }

  if (user?.main_user_data) {
    res.push({
      method: DeliveryMethod.GroupOrder,
      isInternational: false,
      courierAvailable: true,
      officeAvailable: true
    })
  }

  return res
}

@Pipe({
  name: 'appAddFakeDeliveryMethods',
  standalone: true
})
export class AddFakeDeliveryMethodsPipe implements PipeTransform {
  transform(
    methods: AppDeliveryMethodData[] | undefined | null,
    user: AllUserDataResp | undefined | null
  ): DeliveryMethodData[] {
    return addFakeDeliveryMethods(methods, user)
  }
}
