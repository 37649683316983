import { Injectable } from '@angular/core'
import { TopPartnersResponse } from 'common/models/top-partners-response'
import { RequestService } from 'common/services/request.service'

@Injectable({ providedIn: 'root' })
export class TopService {
  constructor(private requestService: RequestService) {}

  getTopRecruiters() {
    return this.requestService.get<TopPartnersResponse>('/api/account/top_partners/top_recruiters', {})
  }
}
