import { BoxberryOffice } from '../../models/boxberry-office'
import { CdekOffice } from '../../models/cdek-office'
import { DPDOffice } from '../../models/dpd-office'
import { RuPostOffice } from '../../models/ru-post-office'
import { KgPostOffice } from 'common/models/kg-post-office'
import { props } from '@ngrx/store'
import { DeliveryMethod } from '../../models/delivery-method'
import { FivePostOffice } from '../../models/five-post-office'
import { Stock } from '../../models/stock'
import { KazpostOffice } from 'common/models/kazpost-office'
import { EuropostOffice } from 'common/models/europost-office'
import { HaypostOffice } from 'common/models/haypost-office'
import { FargoOffice } from 'common/models/fargo-office'

export const cityIdProps = props<{ city_id: number }>()

export interface DeliveryOfficeListBase<Method extends DeliveryMethod, ListType> {
  method: Method
  list: ListType
}

// тип нужен, чтобы проверка x.method === y однозначно определяла тип x.data
export type DeliveryOfficeList =
  | DeliveryOfficeListBase<'Boxberry', BoxberryOffice[]>
  | DeliveryOfficeListBase<'KgPost', KgPostOffice[]>
  | DeliveryOfficeListBase<'Cdek', CdekOffice[]>
  | DeliveryOfficeListBase<'Kazpost', KazpostOffice[]>
  | DeliveryOfficeListBase<'Europost', EuropostOffice[]>
  | DeliveryOfficeListBase<'DPD', DPDOffice[]>
  | DeliveryOfficeListBase<'RuPost', RuPostOffice[]>
  | DeliveryOfficeListBase<'FivePost', FivePostOffice[]>
  | DeliveryOfficeListBase<'Haypost', HaypostOffice[]>
  | DeliveryOfficeListBase<'Fargo', FargoOffice[]>
  | DeliveryOfficeListBase<'SelfPickup', Stock[]>

export interface DeliveryOfficeBase<Method extends DeliveryMethod, OfficeType> {
  method: Method
  office: OfficeType
}

export type DeliveryOffice =
  | DeliveryOfficeBase<'Boxberry', BoxberryOffice>
  | DeliveryOfficeBase<'KgPost', KgPostOffice>
  | DeliveryOfficeBase<'Cdek', CdekOffice>
  | DeliveryOfficeBase<'Kazpost', KazpostOffice>
  | DeliveryOfficeBase<'Europost', EuropostOffice>
  | DeliveryOfficeBase<'DPD', DPDOffice>
  | DeliveryOfficeBase<'RuPost', RuPostOffice>
  | DeliveryOfficeBase<'FivePost', FivePostOffice>
  | DeliveryOfficeBase<'Haypost', HaypostOffice>
  | DeliveryOfficeBase<'Fargo', FargoOffice>
  | DeliveryOfficeBase<'SelfPickup', Stock>

export type AnyDeliveryOfficeList =
  | BoxberryOffice[]
  | KgPostOffice[]
  | CdekOffice[]
  | KazpostOffice[]
  | EuropostOffice[]
  | DPDOffice[]
  | RuPostOffice[]
  | FivePostOffice[]
  | HaypostOffice[]
  | FargoOffice[]
  | Stock[]

export type AnyDeliveryOffice =
  | BoxberryOffice
  | KgPostOffice
  | CdekOffice
  | KazpostOffice
  | EuropostOffice
  | DPDOffice
  | RuPostOffice
  | FivePostOffice
  | HaypostOffice
  | FargoOffice
  | Stock
