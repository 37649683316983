import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { boxberryLoad, boxberryLoadError, boxberryLoadSuccess } from './boxberry.actions'
import { BoxberryService } from './boxberry.service'
import { catchError, map, mergeMap, of } from 'rxjs'

@Injectable()
export class BoxberryEffects {
  constructor(private actions$: Actions, private boxberryService: BoxberryService) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(boxberryLoad),
      mergeMap(({ city_id }) =>
        this.boxberryService.load(city_id).pipe(
          map(data => boxberryLoadSuccess({ data })),
          catchError(error => of(boxberryLoadError({ error })))
        )
      )
    )
  )
}
