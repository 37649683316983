import { createReducer, on } from '@ngrx/store'
import { loadTopClientService } from './top.actions'
import { UsersByLoRatingListResponse } from 'common/models/users-by-lo-rating-list-response'

export const featureKey = 'top-client-service'

export interface State {
  response: UsersByLoRatingListResponse | null
  loading: boolean
}

export const initialState: State = {
  response: null,
  loading: false
}

export const reducer = createReducer(
  initialState,
  on(loadTopClientService.start, state => ({ ...state, loading: true })),
  on(loadTopClientService.success, state => ({ ...state, loading: false })),
  on(loadTopClientService.error, state => ({ ...state, loading: false })),

  on(loadTopClientService.success, (state, response) => {
    return {
      ...state,
      response
    }
  })
)
