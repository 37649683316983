import { createReducer, on } from '@ngrx/store'
import { haypostLoad, haypostLoadError, haypostLoadSuccess, haypostRemove } from './haypost.actions'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { HaypostOffice } from '../../../../../../../common/models/haypost-office'

export const haypostFeatureKey = 'haypost'

export interface State extends EntityState<HaypostOffice> {
  isLoading: boolean
  isLoaded: boolean
}

const selectId = (v: HaypostOffice) => v.code as string

const sortById = (a: HaypostOffice, b: HaypostOffice) => a.code!.localeCompare(b.code!)

export const adapter = createEntityAdapter<HaypostOffice>({
  selectId,
  sortComparer: sortById
})

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  isLoaded: false
})

export const reducer = createReducer(
  initialState,
  on(haypostLoad, state => ({ ...state, isLoading: true })),
  on(haypostLoadSuccess, (state, { data }) => adapter.setAll(data, { ...state, isLoading: false, isLoaded: true })),
  on(haypostLoadError, state => ({ ...state, isLoading: false })),
  on(haypostRemove, () => initialState)
)
