import { Injectable } from '@angular/core'
import { ConstraintsRequest } from 'common/models/constraints-request'
import { MyTeamResponse } from 'common/models/my-team-response'
import { RequestService } from 'common/services/request.service'
import { Datestamp } from '../../../../../../common/models/datestamp'
import { MinionMyTeamRequest } from '../../../../../../common/models/minion-my-team-request'
import { UserIdRequest } from 'common/models/user-id-request'
import { AuthResponse } from 'common/models/auth-response'
import { Observable } from 'rxjs'
import { CicFormRequest } from 'common/models/cic-form-request'
import { SuccessResponse } from 'common/models/success-response'
import { AvailableCicParentsResponse } from 'common/models/available-cic-parents-response'

export type Filters = Omit<ConstraintsRequest, 'pagination'>

export const pageSize = 100

@Injectable({ providedIn: 'root' })
export class CicService {
  constructor(private requestService: RequestService) {}

  getMyTeam(page: number) {
    let filters: Filters = {
      filter: [{ filter_parameter: 'only_cic', input_value: ['true'], filter_type: 'accept', value_group: 'single' }]
    }
    return this.requestService.post<unknown, Required<MyTeamResponse>>('/api/my_team', {
      pagination: {
        page,
        page_size: pageSize
      },
      ...filters
    })
  }

  getMinionDescendants(minionId: number, date?: Datestamp) {
    return this.requestService.post<MinionMyTeamRequest, Required<MyTeamResponse>>(`/api/my_team/first_minions`, {
      date,
      minion_id: minionId
    })
  }

  authAccount(req: UserIdRequest | null): Observable<AuthResponse> {
    if (!req) {
      return this.requestService.post<unknown, AuthResponse>('/api/account/cic/auth-parent', {})
    }

    return this.requestService.post<UserIdRequest, AuthResponse>('/api/account/cic/auth', req)
  }

  addAccount(req: CicFormRequest): Observable<SuccessResponse> {
    return this.requestService.post<CicFormRequest, SuccessResponse>('/api/register/cic', req)
  }

  getAvailableParents(): Observable<AvailableCicParentsResponse> {
    return this.requestService.post<unknown, AvailableCicParentsResponse>('/api/register/cic/available-parents', {})
  }
}
