import { Pipe, PipeTransform } from '@angular/core'
import { Country } from '../../../../../common/models/country'
import { DeliveryMethodData } from '../../../../../common/models/delivery-method-data'
import { ruCountryId } from '../../../shared/utils/constants/country'
import { shouldSkipDelivery } from './should-skip-delivery.pipe'

export const countryHasDeliveryOffices = (
  country: Country | undefined | null,
  deliveryMethod: DeliveryMethodData | undefined | null
): boolean =>
  // пункты выдачи есть в странах еаэс
  Boolean(country && country.is_region_available) &&
  // кроме международной доставки почты россии
  !(country?.id !== ruCountryId && deliveryMethod?.method === 'RuPost') &&
  // кроме фейковых методов доставки
  !shouldSkipDelivery(deliveryMethod?.method)

@Pipe({
  name: 'appCountryHasDeliveryOffices',
  standalone: true
})
export class CountryHasDeliveryOfficesPipe implements PipeTransform {
  transform(country: Country | undefined | null, deliveryMethod: DeliveryMethodData | undefined | null): boolean {
    return countryHasDeliveryOffices(country, deliveryMethod)
  }
}
