import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { UsersByLoRatingListResponse } from 'common/models/users-by-lo-rating-list-response'

export const loadTopClientService = createActionGroup({
  source: 'Client service rating table items load',
  events: {
    start: emptyProps(),
    success: props<UsersByLoRatingListResponse>(),
    error: props<{ error: string }>()
  }
})

export const topClientServiceAllErrors = [loadTopClientService.error]
