import { ErrorsModule } from 'ngx-customapp-errors'
import { JwtModule } from 'ngx-customapp-jwt'
import { createSdkErrorsConfig } from 'common/config/sdk-errors-config'
import { createSdkJwtConfig } from 'common/config/sdk-jwt-config'
import { NoFreshJwtListenerService } from '../services/no-fresh-jwt-listener.service'
import { environment } from '../../environments/environment'

export const sdkImports = [
  ErrorsModule.forRoot(createSdkErrorsConfig(environment.production)),
  JwtModule.forRoot(createSdkJwtConfig('ewaClient', NoFreshJwtListenerService))
]
