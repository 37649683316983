import { createReducer, on } from '@ngrx/store'
import { cdekLoad, cdekLoadError, cdekLoadSuccess, cdekRemove } from './cdek.actions'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { CdekOffice } from '../../../../../../../common/models/cdek-office'

export const cdekFeatureKey = 'cdek'

export interface State extends EntityState<CdekOffice> {
  isLoading: boolean
  isLoaded: boolean
}

const selectId = (v: CdekOffice) => v.code as string

const sortById = (a: CdekOffice, b: CdekOffice) => a.code!.localeCompare(b.code!)

export const adapter = createEntityAdapter<CdekOffice>({
  selectId,
  sortComparer: sortById
})

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  isLoaded: false
})

export const reducer = createReducer(
  initialState,
  on(cdekLoad, state => ({ ...state, isLoading: true })),
  on(cdekLoadSuccess, (state, { data }) => adapter.setAll(data, { ...state, isLoading: false, isLoaded: true })),
  on(cdekLoadError, state => ({ ...state, isLoading: false })),
  on(cdekRemove, () => initialState)
)
