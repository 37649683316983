import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { WrapperComponent } from 'client/src/shared/ui/wrapper/wrapper.component'
import { FlexComponent } from 'client/src/shared/ui/flex/flex.component'
import { ButtonComponent } from 'client/src/shared/ui/button/button.component'
import { RouterModule } from '@angular/router'
import { cookieAnimation } from '../../../../common/animations/cookie.animation'
import { timer } from 'rxjs'
import { translations } from '../../../../common/utils/constants/translations'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'app-cookie-consent',
  standalone: true,
  imports: [CommonModule, WrapperComponent, FlexComponent, ButtonComponent, RouterModule, TranslateModule],
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
  animations: [cookieAnimation],
  providers: [],
  host: { '[class.cookie-consent]': 'true' }
})
export class CookieConsentComponent implements OnInit {
  readonly translations = translations

  private isConsented: boolean = true
  private COOKIE_CONSENT = 'ewaproduct-cookie-consent'
  private COOKIE_CONSENT_EXPIRE_DAYS = 100

  constructor() {}

  ngOnInit() {
    timer(600).subscribe(() => {
      this.isConsented = this.getCookie(this.COOKIE_CONSENT) === '1'
    })
  }

  private getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';')
    let caLen: number = ca.length
    let cookieName = `${name}=`
    let c: string
    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '')
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length)
      }
    }
    return ''
  }

  private deleteCookie(name: string) {
    this.setCookie(name, '', -1)
  }

  private setCookie(name: string, value: string, expireDays: number, path: string = '') {
    let d: Date = new Date()
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000)
    let expires: string = `expires=${d.toUTCString()}`
    let cpath: string = path ? `; path=${path}` : ''
    document.cookie = `${name}=${value}; ${expires}${cpath}`
  }

  public getIsConsented() {
    return this.isConsented
  }

  public consent() {
    if (!this.isConsented) {
      this.setCookie(this.COOKIE_CONSENT, '1', this.COOKIE_CONSENT_EXPIRE_DAYS)
      this.isConsented = true
      //e.preventDefault();
    }

    return this.isConsented
  }
}
