import { createActionGroup, props } from '@ngrx/store'
import { MinionFromMyTeamResponse } from 'common/models/minion-from-my-team-response'
import { Datestamp } from '../../../../../../common/models/datestamp'

export const loadMinionById = createActionGroup({
  source: 'Partner page load minion',
  events: {
    start: props<{ visibleId: number; date?: Datestamp }>(),
    success: props<{ response: MinionFromMyTeamResponse; date?: Datestamp }>(),
    error: props<{ error: string }>()
  }
})

export const allErrors = [loadMinionById.error]
