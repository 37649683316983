import { createReducer, on } from '@ngrx/store'
import { BonusesHistory } from 'common/models/bonuses-history'
import { loadAccountBonusInfo } from './total-income.actions'

export const featureKey = 'total income page'

export interface State {
  response: BonusesHistory | null
  loading: boolean
}

export const initialState: State = {
  loading: false,
  response: null
}

export const reducer = createReducer(
  initialState,
  on(loadAccountBonusInfo.start, state => ({ ...state, loading: true })),
  on(loadAccountBonusInfo.success, (state, { response }) => ({ ...state, loading: false, response })),
  on(loadAccountBonusInfo.error, state => ({ ...state, loading: false }))
)
