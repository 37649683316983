import { createActionGroup, props } from '@ngrx/store'
import { EventListResponse } from 'common/models/event-list-response'

export const getArchiveEventsList = createActionGroup({
  source: 'Archive events page load',
  events: {
    start: props<{ page: number; page_size: number }>(),
    success: props<{ response: EventListResponse }>(),
    error: props<{ error: string }>()
  }
})

export const allErrors = [getArchiveEventsList.error]
