import { Currency } from '../../models/currency'
import { createIsoCodeRecord } from '../core/array'
import { Config } from '../../models/config'

export type ExtendedCurrency = Currency & { rateToBonusProceeded?: number; rateToCommodityProceeded?: number }

export const toExtendedCurrency = (
  currencies: Currency[] | undefined | null,
  config: Config | undefined | null
): Record<string, ExtendedCurrency> => {
  if (!currencies || !config) {
    return createIsoCodeRecord([])
  }

  const adjustmentFactor = config.adjustmen_factor ?? 1

  return createIsoCodeRecord(
    currencies.map<ExtendedCurrency>(c => ({
      ...c,
      rateToBonusProceeded: (c.rate_to_bonus ?? 0) / adjustmentFactor,
      rateToCommodityProceeded: (c.rate_to_commodity ?? 0) / adjustmentFactor
    })) as Required<ExtendedCurrency>[]
  )
}

export const createCurrencyConversions = (
  currencies: Record<string, ExtendedCurrency>,
  defaultCurrencyIsoCode: string
) => {
  return {
    // здесь ок делать проверки через &&, не замечая различий между 0 и undefined,
    // так как 0 после умножения на что угодно остается нулем
    bonusToCurrency: (bonusValue: number | undefined | null, currencyIsoCode: string = defaultCurrencyIsoCode) => {
      const currency: ExtendedCurrency = currencies?.[currencyIsoCode]
      return bonusValue && currency?.rateToBonusProceeded && Math.round(bonusValue * currency.rateToBonusProceeded)
    },
    commodityToCurrency: (
      commodityValue: number | undefined | null,
      currencyIsoCode: string = defaultCurrencyIsoCode
    ) => {
      const currency: ExtendedCurrency = currencies?.[currencyIsoCode]
      return (
        commodityValue &&
        currency?.rateToCommodityProceeded &&
        Math.round(commodityValue * currency.rateToCommodityProceeded)
      )
    },
    currencyToBonus: (currencyValue: number | undefined | null, currencyIsoCode: string = defaultCurrencyIsoCode) => {
      const currency: ExtendedCurrency = currencies?.[currencyIsoCode]
      return (
        currencyValue && currency?.rateToBonusProceeded && Math.round(currencyValue / currency.rateToBonusProceeded)
      )
    },
    currencyToCommodity: (
      currencyValue: number | undefined | null,
      currencyIsoCode: string = defaultCurrencyIsoCode
    ) => {
      const currency: ExtendedCurrency = currencies?.[currencyIsoCode]
      return (
        currencyValue &&
        currency?.rateToCommodityProceeded &&
        Math.round(currencyValue / currency.rateToCommodityProceeded)
      )
    }
  }
}
