import { createReducer, on } from '@ngrx/store'
import { MyTeamResponseMinionsInner } from 'common/models/my-team-response-minions-inner'
import { Navigation } from 'common/models/navigation'
import {
  authAccount,
  addAccount,
  loadMinionDescendants,
  loadMinions,
  removeMinionDescendants,
  setPage,
  getAvailableParents
} from './cic.actions'
import { MinionFromMyTeamResponse } from 'common/models/minion-from-my-team-response'
import { Datestamp } from '../../../../../../common/models/datestamp'
import { ParentsFromMyTeam } from 'common/models/parents-from-my-team'

export const cicFeatureKey = 'cic'

export interface MinionWithLevelDown {
  minion: MyTeamResponseMinionsInner
  isOwner?: boolean
  /** Field for descendant minions */
  levelDown?: number
  descendantsLoaded?: boolean // чтобы не загружать повторно уже загруженных миньонов
}

const getMinionId = (minion: MinionWithLevelDown): number => minion.minion?.minion_data?.main_user_data?.id ?? 0

export interface State {
  minions: MinionWithLevelDown[]
  navigation: Navigation
  currentMinion: MinionFromMyTeamResponse | null
  partnersAmount: {
    all: number
    active: number
  }
  date?: Datestamp
  areFiltersDirty: boolean
  searchInfo: {
    page: number
  }
  isLoading: boolean
  isActionLoading: boolean
  availableParents: ParentsFromMyTeam[]

  addAccountError: boolean
  addAccountSuccess: boolean
}

export const initialState: State = {
  minions: [],
  navigation: { page: 0, size: 10, total: 0 },
  currentMinion: null,
  areFiltersDirty: false,
  partnersAmount: {
    all: 0,
    active: 0
  },
  searchInfo: {
    page: 0
  },
  isLoading: false,
  isActionLoading: false,

  addAccountSuccess: false,
  addAccountError: false,

  availableParents: []
}

export const reducer = createReducer(
  initialState,

  on(getAvailableParents.start, (state, {}) => {
    return { ...state, isActionLoading: true, addAccountSuccess: false, addAccountError: false }
  }),
  on(getAvailableParents.success, (state, { response }) => {
    return { ...state, availableParents: response.parents ?? [], isActionLoading: false }
  }),
  on(getAvailableParents.error, state => {
    return { ...state, isActionLoading: false }
  }),

  on(authAccount.start, state => {
    return { ...state, isActionLoading: true }
  }),
  on(authAccount.success, state => {
    return { ...state, isActionLoading: false }
  }),
  on(authAccount.error, state => {
    return { ...state, isActionLoading: false }
  }),

  on(addAccount.start, state => {
    return { ...state, isActionLoading: true, addAccountSuccess: false, addAccountError: false }
  }),
  on(addAccount.success, state => {
    return { ...state, isActionLoading: false, addAccountSuccess: true, addAccountError: false }
  }),
  on(addAccount.error, state => {
    return { ...state, isActionLoading: false, addAccountSuccess: false, addAccountError: true }
  }),

  on(loadMinions.start, state => ({ ...state, isLoading: true })),
  on(loadMinions.error, state => ({ ...state, isLoading: false })),
  on(loadMinions.success, state => ({ ...state, isLoading: false })),

  on(
    loadMinions.success,
    (state, { date, response: { minions, navigation, number_active_partners, number_partners } }) => {
      return {
        ...state,
        navigation: navigation ?? { page: 0, size: 0, total: 0 },
        minions: (minions ?? []).map(r => ({ minion: r, parentId: undefined })),
        partnersAmount: { all: number_partners ?? 0, active: number_active_partners ?? 0 },
        date
      }
    }
  ),

  on(loadMinionDescendants.start, state => ({ ...state, isLoading: true })),
  on(loadMinionDescendants.success, state => ({ ...state, isLoading: false })),
  on(loadMinionDescendants.error, state => ({ ...state, isLoading: false })),

  on(loadMinionDescendants.success, (state, { minions, minionId }) => {
    const minionIndexWhoseDescendantsWeGot = state.minions.findIndex(
      ({ minion: { minion_data }, descendantsLoaded }) =>
        minion_data.main_user_data?.id === minionId && !descendantsLoaded
    )

    // нет такого миньона или его дети уже были загружены
    if (minionIndexWhoseDescendantsWeGot === -1) {
      return state
    }

    const foundMinion: MinionWithLevelDown = {
      ...state.minions[minionIndexWhoseDescendantsWeGot],
      descendantsLoaded: true
    }

    const newMinions = [...state.minions]
    // заменяем найденного миньона, так как поменяли флаг descendantsLoaded
    newMinions.splice(minionIndexWhoseDescendantsWeGot, 1, foundMinion)
    newMinions.splice(
      minionIndexWhoseDescendantsWeGot + 1,
      0,
      ...minions.map<MinionWithLevelDown>(r => ({
        minion: r,
        levelDown: (foundMinion.levelDown ?? 0) + 1
      }))
    )

    return { ...state, minions: newMinions }
  }),

  on(removeMinionDescendants, (state, { minionId }) => {
    let minionIndex = state.minions.findIndex(minion => getMinionId(minion) === minionId)
    //  нет такого миньона
    if (minionIndex === -1) {
      return state
    }
    const foundMinion: MinionWithLevelDown = {
      ...state.minions[minionIndex],
      descendantsLoaded: false
    }
    // сохраняем миньонов до найденного
    const newMinions = state.minions.slice(0, minionIndex)
    // найденный изменился, поэтому его заново пушим
    newMinions.push(foundMinion)

    // удаляем всех миньонов, которые по уровню ниже
    const startLevelDown = state.minions[minionIndex]?.levelDown ?? 0
    do {
      minionIndex++
    } while ((state.minions?.[minionIndex].levelDown ?? 0) > startLevelDown)
    newMinions.push(...state.minions.slice(minionIndex))
    return {
      ...state,
      minions: newMinions
    }
  }),

  on(setPage, (state, { newPage }) => {
    return { ...state, searchInfo: { ...state.searchInfo, page: newPage } }
  })
)
