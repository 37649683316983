import { createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { Country } from '../../../../../../common/models/country'
import { countriesClear, countriesLoad } from './countries.actions'

export const countriesFeatureKey = 'countries'

export interface State extends EntityState<Country> {
  isLoading: boolean
  isLoaded: boolean
}

const sortByName = (a: Country, b: Country) => (a.name || '').localeCompare(b.name || '')

const selectId = (country: Country) => country.id!

export const adapter = createEntityAdapter<Country>({
  selectId,
  sortComparer: sortByName
})

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  isLoaded: false
})

export const reducer = createReducer(
  initialState,
  on(countriesLoad.start, state => ({
    ...state,
    isLoading: true
  })),
  on(countriesLoad.success, (state, { data }) =>
    adapter.setAll(data, {
      ...state,
      isLoading: false,
      isLoaded: true
    })
  ),
  on(countriesLoad.error, state => ({
    ...state,
    isLoading: false
  })),
  on(countriesClear, () => initialState)
)
