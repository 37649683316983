import { Injectable } from '@angular/core'
import { NewsListResponse } from 'common/models/news-list-response'
import { RequestService } from 'common/services/request.service'
import { GetNewsByIdResponse } from 'common/models/get-news-by-id-response'

@Injectable({ providedIn: 'root' })
export class NewsService {
  constructor(private requestService: RequestService) {}

  getNewsList(page: number, page_size: number) {
    return this.requestService.post<unknown, NewsListResponse>(
      '/api/news/published',
      {},
      { params: { page, page_size } }
    )
  }

  getNewsItem(id: number) {
    return this.requestService.post<unknown, GetNewsByIdResponse>(`/api/news/${id}`, {})
  }
}
