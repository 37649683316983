import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { KazpostService } from './kazpost.service'
import { kazpostLoad, kazpostLoadError, kazpostLoadSuccess } from './kazpost.actions'
import { catchError, map, mergeMap, of } from 'rxjs'

@Injectable()
export class KazpostEffects {
  constructor(private actions$: Actions, private kazpostService: KazpostService) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(kazpostLoad),
      mergeMap(({ city_id }) =>
        this.kazpostService.load(city_id).pipe(
          map(data => kazpostLoadSuccess({ data })),
          catchError(error => of(kazpostLoadError({ error })))
        )
      )
    )
  )
}
