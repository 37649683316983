<div
  class="alert__backdrop"
  *ngIf="visible"
  @appearDisappear
  (@appearDisappear.done)="handleBackdropAnimationEnd()"
  (click)="handleClose()"
></div>

<div class="alert__container" [@tilesAnimation]="((alert.modals$ | async) || []).length">
  <div
    class="alert__modal"
    [class.alert__modal--white]="!darkMode"
    [class.alert__modal--black]="darkMode"
    *ngFor="let modal of (alert.modals$ | async) || []"
  >
    <p class="alert__text">
      {{ modal.title }}
    </p>
    <div class="alert__footer">
      # {{ modal.rid }}

      <app-copy-btn
        class="alert__copy"
        [color]="darkMode ? 'white' : 'gray'"
        [valueToCopy]="'# ' + modal.rid"
      ></app-copy-btn>
    </div>
    <div class="alert__close" (click)="alert.closeOne(modal)"></div>
  </div>
</div>
