import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store'
import { UpdateDeliveryInCartRequest } from '../../../../../common/models/update-delivery-in-cart-request'
import { CartWithProducts } from '../../../../../common/models/cart-with-products'
import { AddCartCityRequest } from '../../../../../common/models/add-cart-city-request'
import { DeliveryOffice } from '../../../../../common/utils/delivery-office/types'
import { Address } from '../../../../../common/models/address'
import { RupostCourierDeliveryKind } from '../../../../../common/models/rupost-courier-delivery-kind'
import { RupostOfficeDeliveryKind } from '../../../../../common/models/rupost-office-delivery-kind'
import { DPDDeliveryKind } from '../../../../../common/models/dpd-delivery-kind'
import { Item } from 'common/models/item'
import { CartItem } from 'common/models/cart-item'
import { UpdateCountryInCartRequest } from '../../../../../common/models/update-country-in-cart-request'
import { UpdatePersonalDataInCartRequest } from '../../../../../common/models/update-personal-data-in-cart-request'
import { AddCartRegionRequest } from '../../../../../common/models/add-cart-region-request'
import { AppDeliveryMethodData } from '../../../features/delivery/lib/delivery-method-data'
import { Country } from '../../../../../common/models/country'
import { OrderIntIdRequest } from 'common/models/order-int-id-request'
import { PaymentAcquiring } from '../../../../../common/models/payment-acquiring'

type Id = number
type Amount = number

export const approveGroupOrder = createActionGroup({
  source: '[Group Order] approve',
  events: {
    start: props<{ req: OrderIntIdRequest }>(),
    success: props<{ cart: CartWithProducts }>(),
    error: props<{ error: string }>()
  }
})

export const addCartItem = createAction('[Cart] add', props<{ item: Item }>())
export const addCartItemSuccess = createAction('[Cart] add success', props<{ cart: CartWithProducts }>())
export const addCartItemError = createAction('[Cart] add error', props<{ error: string }>())

export const getCart = createAction('[Cart] get')

// TODO Переименовать на финальном коммите
export const changeCountryTest = createAction('[Cart] Change Country Test', props<{ country: Country }>())
export const changeCountrySuccessTest = createAction('[Cart] Change Country Test Success')
export const updateTotalCurrencyPrice = createActionGroup({
  source: '[Cart] Update Total Currency Price',
  events: {
    start: emptyProps(),
    success: props<{ prices: any }>(),
    error: props<{ error: string }>()
  }
})
export const getCartSuccess = createAction('[Cart] get success', props<{ cart: CartWithProducts }>())
export const getCartError = createAction('[Cart] get error', props<{ error: string }>())

export const updateCartItem = createAction('[Cart] update', props<{ item: CartItem }>())
export const updateCartItemSuccess = createAction('[Cart] update success', props<{ cart: CartWithProducts }>())
export const updateCartItemError = createAction('[Cart] update error', props<{ error: string }>())

export const deleteCartItem = createAction('[Cart] delete', props<{ item: Item }>())
export const deleteCartItemSuccess = createAction('[Cart] delete success', props<{ cart: CartWithProducts }>())
export const deleteCartItemError = createAction('[Cart] delete error', props<{ error: string }>())

export const updateManyCartItems = createAction('[Cart] update many', props<{ idAmountMap: Map<Id, Amount> }>())
export const updateManyCartItemsSuccess = createAction(
  '[Cart] update many success',
  props<{ cart: CartWithProducts }>()
)
export const updateManyCartItemsError = createAction('[Cart] update many error', props<{ error: string }>())

// Для пакетов
export const updateCartItemsSuccess = createAction('[Cart] update cart items success', props<{ error: string }>())

export const cartEstimateDelivery = createAction(
  '[Cart] estimate delivery',
  props<{ data: UpdateDeliveryInCartRequest }>()
)
export const cartEstimateDeliverySuccess = createAction(
  '[Cart] estimate delivery success',
  props<{ cart: CartWithProducts }>()
)
export const cartEstimateDeliveryError = createAction('[Cart] estimate delivery error', props<{ error: string }>())

export const cartSaveCity = createAction('[Cart] save city', props<{ data: AddCartCityRequest }>())
export const cartSaveCitySuccess = createAction('[Cart] save city success', props<{ cart: CartWithProducts }>())
export const cartSaveCityError = createAction('[Cart] save city error', props<{ error: string }>())

export const cartSaveCountry = createActionGroup({
  source: '[Cart] save country',
  events: {
    start: props<{ data: UpdateCountryInCartRequest }>(),
    success: props<{ cart: CartWithProducts }>(),
    error: props<{ error: string }>()
  }
})

export const cartSaveRegion = createActionGroup({
  source: '[Cart] save region',
  events: {
    start: props<{ data: AddCartRegionRequest }>(),
    success: props<{ cart: CartWithProducts }>(),
    error: props<{ error: string }>()
  }
})

export const cartSaveDeliveryMethod = createAction(
  '[Cart] save delivery method',
  props<{ method: AppDeliveryMethodData }>()
)
export const cartSaveDeliveryMethodSuccess = createAction(
  '[Cart] save delivery method success',
  props<{ cart: CartWithProducts; method: AppDeliveryMethodData }>()
)

export const cartSaveDeliveryYandexInterval = createAction(
  '[Cart] save delivery yandex interval',
  props<{ interval: number }>()
)

export const cartSaveDeliveryMethodError = createAction('[Cart] save delivery method error', props<{ error: string }>())

export const cartSaveDeliveryOffice = createAction('[Cart] save delivery office', props<{ data: DeliveryOffice }>())

export const cartSaveAddress = createAction('[Cart] save address', props<{ data: Address }>())

export const cartSaveRuPostCourierDeliveryKind = createAction(
  '[Cart] save RuPost courier delivery kind',
  props<{ data: RupostCourierDeliveryKind }>()
)

export const cartSaveRuPostToOfficeDeliveryKind = createAction(
  '[Cart] save RuPost to office delivery kind',
  props<{ data: RupostOfficeDeliveryKind }>()
)

export const cartSaveDpdDeliveryKind = createAction('[Cart] save dpd delivery kind', props<{ data: DPDDeliveryKind }>())

export const cartStartChanging = createAction('[Cart] start changing')
export const cartEndChanging = createAction('[Cart] end changing')

export const clearCart = createActionGroup({
  source: 'Cart clear',
  events: {
    start: emptyProps(),
    success: props<{ cart: CartWithProducts }>(),
    error: props<{ error: string }>()
  }
})

export const updatePersonalInfo = createActionGroup({
  source: 'Cart update personal info',
  events: {
    start: props<{ info: UpdatePersonalDataInCartRequest }>(),
    success: props<{ cart: CartWithProducts }>(),
    error: props<{ error: string }>()
  }
})

export const updateStock = createActionGroup({
  source: 'Cart update stock',
  events: {
    start: props<{ stockId: number }>(),
    success: props<{ cart: CartWithProducts }>(),
    error: props<{ error: string }>()
  }
})

export const updateCurrency = createActionGroup({
  source: 'Cart update currency',
  events: {
    start: props<{ currencyId: string }>(),
    success: props<{ cart: CartWithProducts }>(),
    error: props<{ error: string }>()
  }
})

export const getPaymentAcquirings = createActionGroup({
  source: 'Cart get pay acquirings',
  events: {
    start: props<{ currency_iso_code: string; country_id: number }>(),
    success: props<{ acquirings: PaymentAcquiring[] }>(),
    error: props<{ error: string }>()
  }
})

export const allErrors = [
  addCartItemError,
  getCartError,
  deleteCartItemError,
  updateManyCartItemsError,
  cartEstimateDeliveryError,
  cartSaveCityError,
  cartSaveDeliveryMethodError,
  cartSaveCountry.error,
  clearCart.error,
  updatePersonalInfo.error,
  updateStock.error,
  updateCurrency.error,
  getPaymentAcquirings.error
]
