import { Injectable } from '@angular/core'
import { Ticket } from 'common/models/ticket'
import { TicketMessage } from 'common/models/ticket-message'
import { TicketMessageToSend } from 'common/models/ticket-message-to-send'
import { TicketMessagesResponse } from 'common/models/ticket-messages-response'
import { RequestService } from 'common/services/request.service'
import { getBase64File } from 'common/utils/files/get-base64-file'
import { forkJoin, from, map, mergeMap, Observable, take } from 'rxjs'
import { GetTicketByIdResponse } from '../../../../../../../common/models/get-ticket-by-id-response'
import { TicketWithMessage } from '../../../../../../../common/models/ticket-with-message'

export interface TicketWithMessages {
  ticket: Ticket
  messages: TicketMessage[]
}

@Injectable({ providedIn: 'root' })
export class TicketService {
  constructor(private requestService: RequestService) {}

  getTicketWithMessages(id: number): Observable<TicketWithMessages> {
    return forkJoin([this.getTicketInfo(id), this.getTicketMessages(id)]).pipe(
      map(([ticket, messagesResp]) => ({ ticket: ticket.ticket!, messages: messagesResp.messages ?? [] }))
    )
  }

  sendMessage(ticketId: number, message: { message: string; files: File[] }) {
    return from(Promise.all((message.files ?? []).map(f => getBase64File(f))))
      .pipe(take(1))
      .pipe(
        mergeMap(files => {
          let filesToSend: { base64: string; name: string }[] = []

          if (files.length > 0) {
            filesToSend = files
          }
          return this.requestService.post<TicketMessageToSend, TicketWithMessage>(
            `api/tickets/${ticketId}/send_message`,
            {
              message: message.message,
              attachments: filesToSend.map(f => ({ data: f.base64, name: f.name }))
            }
          )
        })
      )
  }

  update(ticket: Ticket): Observable<Ticket> {
    return (
      this.requestService
        .post<Partial<Ticket>[], Ticket[]>(`api/tickets/update`, [ticket])
        // бек присылает в ответе только обновленный тикет
        .pipe(map(ticketsUpdated => ticketsUpdated[0]))
    )
  }

  private getTicketInfo(ticketId: number) {
    return this.requestService.post<unknown, GetTicketByIdResponse>(`api/tickets/${ticketId}`, {})
  }

  private getTicketMessages(ticketId: number) {
    return this.requestService.post<unknown, TicketMessagesResponse>(`api/tickets/${ticketId}/messages`, {}).pipe(
      map(messages => {
        return { messages: messages.messages?.sort((a, b) => (a.sent_at ?? 0) - (b.sent_at ?? 0)) }
      })
    )
  }
}
