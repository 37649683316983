import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { HaypostService } from './haypost.service'
import { haypostLoad, haypostLoadError, haypostLoadSuccess } from './haypost.actions'
import { catchError, map, mergeMap, of } from 'rxjs'

@Injectable()
export class HaypostEffects {
  constructor(private actions$: Actions, private haypostService: HaypostService) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(haypostLoad),
      mergeMap(({ city_id }) =>
        this.haypostService.load(city_id).pipe(
          map(data => haypostLoadSuccess({ data })),
          catchError(error => of(haypostLoadError({ error })))
        )
      )
    )
  )
}
