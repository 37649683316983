import { Injectable } from '@angular/core'
import { TopDonorsResponse } from 'common/models/top-donors-response'
import { RequestService } from 'common/services/request.service'

@Injectable({ providedIn: 'root' })
export class TopService {
  constructor(private _requestService: RequestService) {}

  getTopDonors() {
    return this._requestService.get<TopDonorsResponse>('/api/fund/top_donors', {})
  }
}
