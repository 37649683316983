import { Injectable } from '@angular/core'
import { BehaviorSubject, combineLatestWith, map, Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class DarkModeService {
  private _dark$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private _darkOverlay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  get dark$(): Observable<boolean> {
    return this._dark$.asObservable().pipe(
      combineLatestWith(this._darkOverlay$),
      map(([dark, darkOverlay]) => {
        return dark || darkOverlay
      })
    )
  }

  public darkOn(): void {
    this._dark$.next(true)
  }

  public darkOff(): void {
    this._dark$.next(false)
  }

  public darkOverlay(state: boolean): void {
    this._darkOverlay$.next(state)
  }
}
