import { DeliveryOffice } from '../../../../../common/utils/delivery-office/types'
import { AppDelivery } from './app-delivery'
import { Order } from '../../../../../common/models/order'
import { CartWithProducts } from '../../../../../common/models/cart-with-products'

export const orderOrCartDelivery = (orderOrCart?: Order | CartWithProducts): AppDelivery | undefined => {
  if (orderOrCart?.fargo_delivery) {
    return {
      delivery: orderOrCart.fargo_delivery,
      method: 'Fargo'
    }
  }

  if (orderOrCart?.kgpost_delivery) {
    return {
      delivery: orderOrCart.kgpost_delivery,
      method: 'KgPost'
    }
  }

  if (orderOrCart?.boxberry_delivery) {
    return {
      delivery: orderOrCart.boxberry_delivery,
      method: 'Boxberry'
    }
  }

  if (orderOrCart?.kazpost_delivery) {
    return {
      delivery: orderOrCart.kazpost_delivery,
      method: 'Kazpost'
    }
  }

  if (orderOrCart?.yandex_delivery) {
    return {
      delivery: orderOrCart.yandex_delivery,
      method: 'Yandex'
    }
  }

  if (orderOrCart?.europost_delivery) {
    return {
      delivery: orderOrCart.europost_delivery,
      method: 'Europost'
    }
  }

  if (orderOrCart?.haypost_delivery) {
    return {
      delivery: orderOrCart.haypost_delivery,
      method: 'Haypost'
    }
  }

  if (orderOrCart?.cdek_delivery) {
    return {
      delivery: orderOrCart.cdek_delivery,
      method: 'Cdek'
    }
  }

  if (orderOrCart?.dpd_delivery) {
    return {
      delivery: orderOrCart.dpd_delivery,
      method: 'DPD'
    }
  }

  if (orderOrCart?.rupost_delivery) {
    return {
      delivery: orderOrCart.rupost_delivery,
      method: 'RuPost'
    }
  }

  if (orderOrCart?.five_post_delivery) {
    return {
      delivery: orderOrCart.five_post_delivery,
      method: 'FivePost'
    }
  }

  if (orderOrCart?.self_pickup_delivery) {
    return {
      delivery: orderOrCart.self_pickup_delivery,
      method: 'SelfPickup'
    }
  }

  return undefined
}

export const orderOrCartDeliveryOffice = (order?: Order | CartWithProducts): DeliveryOffice | undefined => {
  if (order?.fargo_delivery?.office) {
    return {
      method: 'Fargo',
      office: order.fargo_delivery.office
    }
  }

  if (order?.kgpost_delivery?.office) {
    return {
      method: 'KgPost',
      office: order.kgpost_delivery.office
    }
  }
  if (order?.boxberry_delivery?.office) {
    return {
      method: 'Boxberry',
      office: order.boxberry_delivery.office
    }
  }
  if (order?.kazpost_delivery?.office) {
    return {
      method: 'Kazpost',
      office: order.kazpost_delivery.office
    }
  }
  if (order?.europost_delivery?.office) {
    return {
      method: 'Europost',
      office: order.europost_delivery.office
    }
  }
  if (order?.cdek_delivery?.office) {
    return {
      method: 'Cdek',
      office: order.cdek_delivery.office
    }
  }
  if (order?.dpd_delivery?.office) {
    return {
      method: 'DPD',
      office: order.dpd_delivery.office
    }
  }
  if (order?.rupost_delivery?.office) {
    return {
      method: 'RuPost',
      office: order.rupost_delivery?.office
    }
  }
  if (order?.five_post_delivery?.office) {
    return {
      method: 'FivePost',
      office: order.five_post_delivery?.office
    }
  }
  if (order?.self_pickup_delivery?.office) {
    return {
      method: 'SelfPickup',
      office: order.self_pickup_delivery?.office
    }
  }

  return undefined
}
