import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { TopService } from '../api/top.service'
import { loadTopRecruiters } from './top.actions'

@Injectable()
export class TopEffects {
  constructor(private actions$: Actions, private topService: TopService) {}

  loadMinions = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTopRecruiters.start),
      mergeMap(() =>
        this.topService.getTopRecruiters().pipe(
          map(response => loadTopRecruiters.success(response)),
          catchError(error => of(loadTopRecruiters.error({ error: String(error) })))
        )
      )
    )
  )
}
