import { Injectable } from '@angular/core'
import { Order, OrderIdRequest, PayOrderRequestV2 } from 'common/models/models'
import { RequestService } from 'common/services/request.service'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class CheckoutService {
  constructor(private _requestService: RequestService) {}

  payOrder(req: PayOrderRequestV2): Observable<Order> {
    if (req.payment_method === 'TestPay') {
      return this._requestService.post('/api/test/order/pay', req)
    }

    return this._requestService.post('/api/orders/v2/pay', req)
  }

  checkPayment(orderId: string) {
    return this._requestService.post<OrderIdRequest, Order>('api/orders/check_payment', {
      order_id: orderId
    })
  }
}
