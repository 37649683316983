import { CanActivateFn } from '@angular/router'
import { QueryParams } from '../../../../common/utils/params/query-params'
import { handleInt } from '../../../../common/utils/form/numbers'
import { inject } from '@angular/core'
import { JwtService } from 'ngx-customapp-jwt'

export const godModeGuard: CanActivateFn = route => {
  const accessToken = route.queryParams[QueryParams.accessToken]
  const accessTokenExpiresAt = handleInt(route.queryParams[QueryParams.accessTokenExpiresAt])
  const refreshToken = route.queryParams[QueryParams.refreshToken]
  const refreshTokenExpiresAt = handleInt(route.queryParams[QueryParams.refreshTokenExpiresAt])
  const jwtService = inject(JwtService)
  if (accessToken && accessTokenExpiresAt && refreshToken && refreshTokenExpiresAt) {
    jwtService.setJwt({
      accessToken: {
        token: accessToken,
        expiresAt: accessTokenExpiresAt
      },
      refreshToken: {
        token: refreshToken,
        expiresAt: refreshTokenExpiresAt
      }
    })
  }
  return true
}
