import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { loadMinionById } from './partner.actions'
import { PartnerService } from '../api/partner.service'

@Injectable()
export class PartnerEffects {
  constructor(private actions$: Actions, private teamService: PartnerService) {}
  loadMinion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMinionById.start),
      mergeMap(({ visibleId, date }) =>
        this.teamService.getMinionById({ minion_visible_id: visibleId, date }).pipe(
          map(response => loadMinionById.success({ response, date })),
          catchError(error => of(loadMinionById.error({ error: String(error) })))
        )
      )
    )
  )
}
