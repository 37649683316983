import { Component, Output, EventEmitter, Input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DialogHeaderComponent } from 'client/src/shared/ui/dialog/dialog-header/dialog-header.component'

@Component({
  selector: 'app-dialog-logo-header',
  standalone: true,
  imports: [CommonModule, DialogHeaderComponent],
  templateUrl: './dialog-logo-header.component.html',
  styleUrls: ['./dialog-logo-header.component.scss']
})
export class DialogLogoHeaderComponent {
  @Output() closeClick = new EventEmitter<void>()
  @Input() text?: string
}
