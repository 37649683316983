import { createReducer, on } from '@ngrx/store'
import { sendTicket } from './create-ticket.actions'
import { Ticket } from 'common/models/ticket'

export const featureKey = 'ticket creation form'

export interface State {
  loading: boolean
  response: Ticket | null
}

export const initialState: State = {
  loading: false,
  response: null
}

export const reducer = createReducer(
  initialState,
  on(sendTicket.start, state => {
    return { ...state, loading: true }
  }),
  on(sendTicket.success, (state, response) => {
    return { ...state, response, loading: false }
  }),
  on(sendTicket.error, state => {
    return { ...state, loading: false }
  })
)
