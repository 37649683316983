import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { fargoLoad, fargoLoadError, fargoLoadSuccess } from './fargo.actions'
import { FargoService } from './fargo.service'
import { catchError, map, mergeMap, of } from 'rxjs'

@Injectable()
export class FargoEffects {
  constructor(private actions$: Actions, private fargoService: FargoService) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fargoLoad),
      mergeMap(({ city_id }) =>
        this.fargoService.load(city_id).pipe(
          map(data => fargoLoadSuccess({ data })),
          catchError(error => of(fargoLoadError({ error })))
        )
      )
    )
  )
}
