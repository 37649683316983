import { inject, Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { Constants } from '../../../../../common/utils/constants/constants'
import { WindowRefService } from '../../../../../common/services/window.service'

export interface NotifyModal {
  title: string
  rid: number
}

@Injectable({ providedIn: 'root' })
export class AlertService {
  public modals$ = new BehaviorSubject<NotifyModal[]>([])
  public removeComponent$ = new BehaviorSubject<boolean>(true)
  private _timeouts: number[] = []
  private _window = inject(WindowRefService)

  error(modal: NotifyModal, stuck?: boolean): void {
    const modals = this.modals$.getValue()
    modals.push(modal)
    this.modals$.next(modals)
    this.removeComponent$.next(false)

    if (!stuck) {
      this._timeouts.push(
        this._window.nativeWindow.setTimeout(() => {
          if (this.modals$.getValue().length > 0) {
            this.closeOne(modal)
          }
        }, Constants.NOTIFY_ERROR)
      )
    }
  }

  closeEverything(): void {
    this.modals$.next([])
    for (const x of this._timeouts) this._window.nativeWindow.clearTimeout(x)
    this._timeouts = []
  }

  closeOne(modal: NotifyModal): void {
    if (this.modals$.getValue().length <= 1) {
      this.closeEverything()
    } else {
      this.modals$.next(this.modals$.getValue().filter(x => JSON.stringify(x) !== JSON.stringify(modal)))
    }
  }
}
