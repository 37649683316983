import { createReducer, on } from '@ngrx/store'
import { loadBonusInfo } from './bonus-info.actions'
import { PurchaseSettlementResponse } from 'common/models/purchase-settlement-response'

export const featureKey = 'bonus info'

export interface State {
  response: PurchaseSettlementResponse | null
}

export const initialState: State = {
  response: null
}

export const reducer = createReducer<State>(
  initialState,
  on(loadBonusInfo.success, (state, { response }) => {
    return { ...state, response }
  })
)
