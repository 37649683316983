import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, of } from 'rxjs'
import { TopService } from '../api/top.service'
import { loadTopDonors } from './top.actions'

@Injectable()
export class TopEffects {
  loadMinions = createEffect(() =>
    this._actions$.pipe(
      ofType(loadTopDonors.start),
      mergeMap(() =>
        this._topService.getTopDonors().pipe(
          map(response => loadTopDonors.success(response)),
          catchError(error => of(loadTopDonors.error({ error: String(error) })))
        )
      )
    )
  )

  constructor(private _actions$: Actions, private _topService: TopService) {}
}
