import { Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { EMPTY, mergeMap, of } from 'rxjs'
import { deliveryIsCourierSetValue } from './delivery-is-courier.actions'
import { cartSaveDeliveryMethodSuccess, getCartSuccess } from '../../cart/cart.actions'
import { AppState } from '../../../state'
import { selectCartDeliveryMethod } from '../../cart/cart.selectors'
import { Store } from '@ngrx/store'

@Injectable()
export class DeliveryIsCourierEffects {
  constructor(private actions$: Actions, private store: Store<AppState>) {}

  setDefault$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartSaveDeliveryMethodSuccess),
      // только такой флаг isCourier обеспечивает корректность запроса на список пунктов выдачи
      mergeMap(({ method }) => of(deliveryIsCourierSetValue({ data: !method.officeAvailable })))
    )
  )

  setFromCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCartSuccess),
      concatLatestFrom(() => this.store.select(selectCartDeliveryMethod)),
      mergeMap(([, method]) => {
        if (method === undefined) {
          return EMPTY
        }
        // только такой флаг isCourier обеспечивает корректность запроса на список пунктов выдачи
        return of(deliveryIsCourierSetValue({ data: !method.officeAvailable }))
      })
    )
  )
}
