import { Injectable } from '@angular/core'
import { ConstraintsRequest } from 'common/models/constraints-request'
import { MyTeamResponse } from 'common/models/my-team-response'
import { RequestService } from 'common/services/request.service'
import { Datestamp } from '../../../../../../common/models/datestamp'
import { MinionMyTeamRequest } from '../../../../../../common/models/minion-my-team-request'

export type Filters = Omit<ConstraintsRequest, 'pagination'>

export const pageSize = 100

@Injectable({ providedIn: 'root' })
export class TeamService {
  constructor(private requestService: RequestService) {}

  getMyTeam(page: number, filters?: Filters) {
    return this.requestService.post<unknown, Required<MyTeamResponse>>('/api/my_team', {
      pagination: {
        page,
        page_size: pageSize
      },
      ...filters
    })
  }

  getMinionDescendants(minionId: number, date?: Datestamp) {
    return this.requestService.post<MinionMyTeamRequest, Required<MyTeamResponse>>(`/api/my_team/first_minions`, {
      date,
      minion_id: minionId
    })
  }
}
