import { Injectable } from '@angular/core'
import { UsersByLoRatingListResponse } from 'common/models/users-by-lo-rating-list-response'
import { RequestService } from 'common/services/request.service'

@Injectable({ providedIn: 'root' })
export class TopService {
  constructor(private requestService: RequestService) {}

  getTopClientService() {
    return this.requestService.get<UsersByLoRatingListResponse>('/api/account/top_partners/client_service_v2', {})
  }
}
