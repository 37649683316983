import { createActionGroup, props } from '@ngrx/store'
import { Event } from 'common/models/event'

export const getEventItem = createActionGroup({
  source: 'Get event item',
  events: {
    start: props<{ id: number }>(),
    success: props<{ event: Event }>(),
    error: props<{ error: string }>()
  }
})

export const allErrors = [getEventItem.error]
