import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { GradesRatingListResponse } from 'common/models/grades-rating-list-response'

export const loadTopGrades = createActionGroup({
  source: 'Grades rating table items load',
  events: {
    start: emptyProps(),
    success: props<GradesRatingListResponse>(),
    error: props<{ error: string }>()
  }
})

export const topGradesAllErrors = [loadTopGrades.error]
