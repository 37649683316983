import { createReducer, on } from '@ngrx/store'
import { loadThegameData } from './thegame.actions'
import { TopPartnersGame0824Response } from 'common/models/top-partners-game0824-response'

export const thegameFeatureKey = 'thegame'

export interface State {
  data: TopPartnersGame0824Response | null
  isLoading: boolean
}

export const initialState: State = {
  data: null,
  isLoading: false
}

export const reducer = createReducer(
  initialState,
  on(loadThegameData.start, state => ({
    ...state,
    isLoading: true
  })),
  on(loadThegameData.error, state => ({
    ...state,
    isLoading: false
  })),
  on(loadThegameData.success, (state, { data }) => ({
    ...state,
    isLoading: false,
    data
  }))
)
