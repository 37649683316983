import { Pipe, PipeTransform } from '@angular/core'
import { Grade } from '../../models/grade'
import { RoleUser } from '../../models/role-user'
import { GRADE_SHORT_ENG } from '../../utils/minion/grades'

export const gradShort = (grade: Grade | undefined | null, role: RoleUser | undefined | null): string =>
  role === RoleUser.RolePartner ? (grade ? GRADE_SHORT_ENG[grade] : '') : 'CL'

@Pipe({
  name: 'gradeShort',
  standalone: true
})
export class GradeShortPipe implements PipeTransform {
  transform(grade: Grade | undefined | null, role: RoleUser | undefined | null): string {
    return gradShort(grade, role)
  }
}
