import { Injectable } from '@angular/core'
import { RegRequest } from 'common/models/reg-request'
import { RequestService } from 'common/services/request.service'

@Injectable({ providedIn: 'root' })
export class RegisterPersonService {
  constructor(private readonly requestService: RequestService) {}

  registerPerson(data: RegRequest, who: 'partner' | 'client') {
    const url = `api/register/${who}`
    return this.requestService.post<RegRequest, void>(url, data)
  }
}
