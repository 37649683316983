import { Injectable } from '@angular/core'
import videojs from 'video.js'
import { VideoPlayer } from '../components/video/video.component'

export interface ICustomWindow extends Window {
  videojs: (element: Element, options: videojs.PlayerOptions) => VideoPlayer
}

@Injectable({ providedIn: 'root' })
export class WindowRefService {
  get nativeWindow(): ICustomWindow {
    return window as unknown as ICustomWindow
  }
}
