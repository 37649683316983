import { createReducer, on } from '@ngrx/store'
import { Order } from 'common/models/order'
import {
  checkPaymentStatus,
  clearBonusValues,
  clearCreatedOrder,
  isCurrencyForbiddenToChange,
  payOrder,
  setBonusAmountError,
  setBonusValues
} from './pay.actions'

export const payFeatureKey = 'pay'

export interface State {
  isForbiddenToChangeCurrency: boolean
  paymentLoading: boolean
  createdOrder: Order | null
  bonusAmountError: boolean
  bonusValues: {
    gift: number | null
    bonus: number | null
  }
  checkingPaymentStatus: boolean
}

export const initialState: State = {
  isForbiddenToChangeCurrency: false,
  createdOrder: null,
  paymentLoading: false,
  bonusAmountError: false,
  bonusValues: { bonus: null, gift: null },
  checkingPaymentStatus: false
}

export const reducer = createReducer<State>(
  initialState,
  on(payOrder.start, state => ({ ...state, paymentLoading: true })),
  on(payOrder.error, state => ({ ...state, paymentLoading: false })),

  on(payOrder.success, (state, { paymentOrder }) => {
    return {
      ...state,
      bonusValues: initialState.bonusValues,
      bonusAmountError: initialState.bonusAmountError,
      createdOrder: paymentOrder,
      paymentLoading: false
    }
  }),

  on(checkPaymentStatus.start, state => {
    return { ...state, checkingPaymentStatus: true }
  }),
  on(checkPaymentStatus.error, state => {
    return { ...state, checkingPaymentStatus: false }
  }),
  on(checkPaymentStatus.success, (state, { status }) => {
    return { ...initialState, createdOrder: status, checkingPaymentStatus: false }
  }),

  on(isCurrencyForbiddenToChange, (state, { isForbidden }) => ({ ...state, isForbiddenToChangeCurrency: isForbidden })),

  on(clearCreatedOrder, state => ({ ...state, paymentOrder: null })),
  on(setBonusAmountError, (state, { isError }) => ({ ...state, bonusAmountError: isError })),
  on(setBonusValues, (state, bonusValues) => ({ ...state, bonusValues })),
  on(clearBonusValues, state => ({
    ...state,
    bonusValues: initialState.bonusValues,
    bonusAmountError: initialState.bonusAmountError
  }))
)
