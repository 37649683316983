import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { AppState } from '../../../store/state'
import { NewPromoService } from '../new-promo.service'
import { catchError, map, mergeMap, of } from 'rxjs'
import { getNewPromoData } from './new-promo.actions'

@Injectable()
export class NewPromoEffects {
  constructor(private actions$: Actions, private newPromoService: NewPromoService, private store: Store<AppState>) {}

  getTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getNewPromoData.start),
      mergeMap(() =>
        this.newPromoService.getBonuses().pipe(
          map(tokens => {
            return getNewPromoData.success({ tokens })
          }),
          catchError(error => of(getNewPromoData.error({ error: String(error) })))
        )
      )
    )
  )
}
