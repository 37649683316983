import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { FamilyCreateOneRequest } from '../../../../../../common/models/family-create-one-request'
import { FamilyCreateThreeRequest } from '../../../../../../common/models/family-create-three-request'
import { errorProps } from '../../../../../../common/utils/store/types'

export const familyAgreementCreateOne = createActionGroup({
  source: '[FamilyAgreement] create one',
  events: {
    start: props<{ req: FamilyCreateOneRequest }>(),
    success: emptyProps(),
    error: errorProps
  }
})

export const familyAgreementCreateBoth = createActionGroup({
  source: '[FamilyAgreement] create both',
  events: {
    start: props<{ req: FamilyCreateThreeRequest }>(),
    success: emptyProps(),
    error: errorProps
  }
})
