import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { Config } from 'common/models/config'
import { Currency } from 'common/models/currency'
import { Country } from 'common/models/country'

export const loadConfig = createActionGroup({
  source: 'Global load config',
  events: {
    start: emptyProps(),
    success: props<{ config: Config }>(),
    error: props<{ error: string }>()
  }
})

export const loadCountries = createActionGroup({
  source: 'Global load countries',
  events: {
    start: emptyProps(),
    success: props<{ countries: Country[] }>(),
    error: props<{ error: string }>()
  }
})

export const loadCurrencies = createActionGroup({
  source: 'Global load currencies',
  events: {
    start: emptyProps(),
    success: props<{ currencies: Currency[] }>(),
    error: props<{ error: string }>()
  }
})
