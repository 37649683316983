import { Pipe, PipeTransform } from '@angular/core'
import { DeliveryMethod } from '../../../../../common/models/delivery-method'

export const shouldSkipDelivery = (deliveryMethod: DeliveryMethod | undefined | null): boolean => {
  return deliveryMethod === DeliveryMethod.FakeDelivery || deliveryMethod === DeliveryMethod.GroupOrder
}

@Pipe({
  name: 'appShouldSkipDelivery',
  standalone: true
})
export class ShouldSkipDeliveryPipe implements PipeTransform {
  transform(deliveryMethod: DeliveryMethod | undefined | null): boolean {
    return shouldSkipDelivery(deliveryMethod)
  }
}
