import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  loginAgain,
  loginAgainErrored,
  loginAgainSucceed,
  loginSucceed,
  logout,
  logoutSucceed
} from '../../../../common/store/jwt/jwt.actions'
import { EMPTY, map, mergeMap, of, tap } from 'rxjs'
import { Router } from '@angular/router'
import { getCart, updateTotalCurrencyPrice } from '../checkout/cart/cart.actions'
import { deleteJwt, deleteJwtSucceed, loadUserInfo } from './auth.actions'
import { JwtService } from 'ngx-customapp-jwt'
import { Credentials, jwtToCredentials } from '../../../../common/utils/user/auth'
import { AuthResponse } from '../../../../common/models/auth-response'
import { UserInfo } from '../../../../common/utils/user/user'
import { NotifyService } from '../../app/services/notify.service'
import { Store } from '@ngrx/store'
import { AppState } from 'client/src/store/state'
import { applyToken } from 'client/src/features/checkout/apply-referrer-token/model/apply-token.actions'
import { clearFavorites } from '../shop/shop.actions'

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private jwtService: JwtService<Credentials, AuthResponse, UserInfo>,
    private notify: NotifyService,
    private store: Store<AppState>
  ) {}

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutSucceed),
        tap(() => {
          void this.router.navigate(['/']).then(() => {
            // Обнуляем реф.ссылку после выхода из ЛК
            this.store.dispatch(applyToken({ token: '' }))
            this.store.dispatch(updateTotalCurrencyPrice.start())

            // Убираем сердечки
            this.store.dispatch(clearFavorites())
          })
        })
      ),
    { dispatch: false }
  )

  checkRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginSucceed),
      mergeMap(({ response }) => {
        if (response.client_partner) {
          return EMPTY
        }
        this.notify.error('Вам нужно войти на другом сайте - панели администратора')
        return of(logout({ fromAllDevices: false }))
      })
    )
  )

  loadCart$ = createEffect(() =>
    this.actions$.pipe(
      // корзина загружается после успешного входа, чтобы загрузить корзину юзера
      // после неуспешного входа, чтобы загрузить анонимную корзину
      // после выхода, чтобы загрузить анонимную корзину
      ofType(loginSucceed, logoutSucceed, loginAgainSucceed, loginAgainErrored),
      mergeMap(() => of(getCart()))
    )
  )

  deleteJwtSession = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteJwt),
      tap(() => this.jwtService.deleteJwt()),
      map(() => deleteJwtSucceed())
    )
  )

  loadUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserInfo),
      mergeMap(() => this.jwtService.freshJwt(true)),
      mergeMap(jwt => {
        if (jwt?.accessToken) {
          return of(loginAgain({ credentials: jwtToCredentials(jwt.accessToken) }))
        }
        return EMPTY
      })
    )
  )
}
