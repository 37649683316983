import { createActionGroup, emptyProps, props } from '@ngrx/store'

export const getNewPromoData = createActionGroup({
  source: '[NewPromo] Get NewPromo Data',
  events: {
    start: emptyProps(),
    success: props<{ tokens: any }>(),
    error: props<{ error: string }>()
  }
})
