import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Injectable } from '@angular/core'
import { tap } from 'rxjs'
import { allErrors as cartErrors } from '../checkout/cart/cart.actions'
import { allErrors as citiesErrors } from '../../../../common/store/geo-search/geo-search.actions'
import { allErrors as deliveryOfficesErrors } from '../checkout/delivery/delivery-office/delivery-office.actions'
import { allErrors as savedAddressesErrors } from 'client/src/features/saved-addresses/model/saved-addresses.actions'
import { allErrors as authErrors } from 'client/src/features/auth/model/auth.actions'
import { allErrors as registerPersonErrors } from 'client/src/features/Registration/register-person'
import { allErrors as referralErrors } from '../../features/Registration/referral/model/register-via-referral.actions'
import { allErrors as orderErrors } from 'common/store/orders/orders.actions'
import { allErrors as deliveryCountriesErrors } from '../../features/geo-search/country-search/model/countries.actions'
import { allErrors as groupVolumeErrors } from '../../widgets/account/group-volume-table/model/group-volume.actions'
import { allErrors as transfersHistoryErrors } from 'client/src/widgets/account/transfer-bonus/model/transfer-history.actions'
import { allErrors as transferBonusErrors } from 'client/src/widgets/account/transfer-bonus/model/transfer-bonus.actions'
import { allErrors as teamErrors } from 'client/src/widgets/account/team-table/model/team.actions'
import { allErrors as partnerPageErrors } from 'client/src/pages/account/partner-page/model/partner.actions'
import { allErrors as createTIcketErrors } from 'client/src/pages/account/support-page/create-ticket-page/model/create-ticket.actions'
import { allErrors as loadTicketsErrors } from 'client/src/widgets/support/tickets-table/model/tickets-table.actions'
import { allErrors as ticketErrors } from 'client/src/pages/account/support-page/ticket-page/model/ticket.actions'
import { totalIncomeErrors } from 'client/src/pages/account/total-income-page/model/total-income.actions'
import { allErrors as payErrors } from 'client/src/store/checkout/pay/pay.actions'
import { allErrors as bonusInfoErrors } from '../../widgets/checkout/bonus-info-after-order/model/bonus-info.actions'
import { allErrors as becomePartnerErrors } from 'client/src/features/account/become-partner/model/become-partner.actions'
import { topGradesAllErrors } from 'client/src/widgets/top-grades-table/model/top.actions'
import { allErrors as reviewListErrors } from 'client/src/widgets/review/model/review-list.actions'
import { loginErrored } from 'common/store/jwt/jwt.actions'
import { NotifyModal, AlertService } from '@shared/components/alert/alert.service'

@Injectable()
export class ErrorEffects {
  showErrors$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          ...cartErrors,
          ...citiesErrors,
          ...deliveryOfficesErrors,
          ...savedAddressesErrors,
          ...authErrors,
          ...registerPersonErrors,
          ...referralErrors,
          ...orderErrors,
          ...deliveryCountriesErrors,
          ...groupVolumeErrors,
          ...transfersHistoryErrors,
          ...transferBonusErrors,
          ...teamErrors,
          ...partnerPageErrors,
          ...createTIcketErrors,
          ...loadTicketsErrors,
          ...ticketErrors,
          ...totalIncomeErrors,
          ...payErrors,
          ...bonusInfoErrors,
          ...becomePartnerErrors,
          ...topGradesAllErrors,
          ...reviewListErrors,
          loginErrored
        ),
        tap(({ error }) => {
          // здесь ошибка всегда в человекочитаемом, переведенном виде.
          // переводы ошибок лежат в frontend/common/config/sdk-errors-config.ts
          this._alert.error(this._parseError(error), false)
        })
      ),
    { dispatch: false }
  )

  constructor(private _actions$: Actions, private _alert: AlertService) {}

  private _parseError(error: string): NotifyModal {
    const match: RegExpMatchArray | null = error.match(/\(rid:\s*(\d+)\)/)
    const rid: number = match ? Number.parseInt(match[1], 10) : 0
    const title = error.replace(/\s*\(rid:\s*\d+\)\s*/, '').trim()
    return { title, rid }
  }
}
