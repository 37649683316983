import { createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { FivePostOffice } from '../../../../../../../common/models/five-post-office'
import { fivePostLoad, fivePostLoadError, fivePostLoadSuccess, fivePostRemove } from './five-post.actions'

export const fivePostFeatureKey = 'fivePost'

export interface State extends EntityState<FivePostOffice> {
  isLoading: boolean
  isLoaded: boolean
}

const selectId = (v: FivePostOffice) => v.code ?? ''

const sortById = (a: FivePostOffice, b: FivePostOffice) => selectId(a).localeCompare(selectId(b))

export const adapter = createEntityAdapter<FivePostOffice>({
  selectId,
  sortComparer: sortById
})

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  isLoaded: false
})

export const reducer = createReducer(
  initialState,
  on(fivePostLoad, state => ({ ...state, isLoading: true })),
  on(fivePostLoadSuccess, (state, { data }) => adapter.setAll(data, { ...state, isLoading: false, isLoaded: true })),
  on(fivePostLoadError, state => ({ ...state, isLoading: false })),
  on(fivePostRemove, () => initialState)
)
