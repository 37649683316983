import { Injectable } from '@angular/core'
import { BonusesHistory } from 'common/models/bonuses-history'
import { Datestamp } from 'common/models/datestamp'
import { RequestService } from 'common/services/request.service'
import { DateTime } from 'luxon'
import { toDatestamp } from '../../../../../../common/utils/core/date'

@Injectable({ providedIn: 'root' })
export class TotalIncomeService {
  constructor(private requestService: RequestService) {}

  getHistoryInfo(date?: DateTime) {
    const dateToTransfer: Datestamp | undefined = toDatestamp(date)
    return this.requestService.post<{ date: Datestamp } | unknown, BonusesHistory>(
      '/api/account/history',
      dateToTransfer ? { date: dateToTransfer } : {}
    )
  }
}
