import { createReducer, on } from '@ngrx/store'
import { TopDonorsResponse } from 'common/models/top-donors-response'
import { loadTopDonors } from './top.actions'

export const featureKey = 'top-donors'

export interface State {
  response: TopDonorsResponse | null
  loading: boolean
}

export const initialState: State = {
  response: null,
  loading: false
}

export const reducer = createReducer(
  initialState,
  on(loadTopDonors.start, state => ({ ...state, loading: true })),
  on(loadTopDonors.success, state => ({ ...state, loading: false })),
  on(loadTopDonors.error, state => ({ ...state, loading: false })),

  on(loadTopDonors.success, (state, response) => {
    return {
      ...state,
      response
    }
  })
)
