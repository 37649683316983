import { createReducer, on } from '@ngrx/store'
import { loadMinionById } from './partner.actions'
import { MinionFromMyTeamResponse } from 'common/models/minion-from-my-team-response'
import { Datestamp } from '../../../../../../common/models/datestamp'

export const featureKey = 'current_partner'

export interface State {
  currentMinion: MinionFromMyTeamResponse | null
  date?: Datestamp
  isLoading: boolean
}

export const initialState: State = {
  currentMinion: null,
  isLoading: false
}

export const reducer = createReducer(
  initialState,

  on(loadMinionById.start, state => {
    return { ...state, isLoading: true }
  }),

  on(loadMinionById.error, state => {
    return { ...state, isLoading: false }
  }),

  on(loadMinionById.success, (state, { response, date }) => {
    return { ...state, currentMinion: response, date, isLoading: false }
  })
)
