import { createActionGroup, props } from '@ngrx/store'
import { NewsListResponse } from 'common/models/news-list-response'
import { GetNewsByIdResponse } from 'common/models/get-news-by-id-response'

export const getNewsList = createActionGroup({
  source: 'News page load',
  events: {
    start: props<{ page: number; page_size: number }>(),
    success: props<{ response: NewsListResponse }>(),
    error: props<{ error: string }>()
  }
})

export const getNewsItem = createActionGroup({
  source: 'Get news item',
  events: {
    start: props<{ id: number }>(),
    success: props<{ response: GetNewsByIdResponse }>(),
    error: props<{ error: string }>()
  }
})

export const allErrors = [getNewsList.error, getNewsItem.error]
