import { createReducer, on } from '@ngrx/store'
import { loadTopRecruiters } from './top.actions'
import { TopPartnersResponse } from 'common/models/top-partners-response'

export const featureKey = 'top-recruiters'

export interface State {
  response: TopPartnersResponse | null
  loading: boolean
}

export const initialState: State = {
  response: null,
  loading: false
}

export const reducer = createReducer(
  initialState,
  on(loadTopRecruiters.start, state => ({ ...state, loading: true })),
  on(loadTopRecruiters.success, state => ({ ...state, loading: false })),
  on(loadTopRecruiters.error, state => ({ ...state, loading: false })),

  on(loadTopRecruiters.success, (state, response) => {
    return {
      ...state,
      response
    }
  })
)
