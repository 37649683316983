import { createReducer, on } from '@ngrx/store'
import * as newsActions from './news.actions'
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { News } from 'common/models/news'
import { Navigation } from 'common/models/navigation'

const selectNewsId = (news: News) => news.id ?? -1

export const adapter: EntityAdapter<News> = createEntityAdapter<News>({ selectId: selectNewsId })

export const { selectAll } = adapter.getSelectors()

export const featureKey = 'news'

export interface State extends EntityState<News> {
  loadingNews: boolean
  navigation: Navigation | null
  currentNewsItem: News | null
  prevNewsId: number
  nextNewsId: number
  loadingNewsItem: boolean
}

export const initialState: State = adapter.getInitialState({
  loadingNews: false,
  navigation: null,
  currentNewsItem: null,
  prevNewsId: 0,
  nextNewsId: 0,
  loadingNewsItem: false
})

export const reducer = createReducer(
  initialState,
  on(newsActions.getNewsList.start, state => ({ ...state, loadingNews: true })),
  on(newsActions.getNewsList.success, (state, { response }) => ({
    ...adapter.setAll(response.news ?? [], state),
    navigation: response.navigation ?? null,
    loadingNews: false
  })),
  on(newsActions.getNewsList.error, state => ({ ...state, loadingNews: false })),

  on(newsActions.getNewsItem.start, state => ({ ...state, loadingNewsItem: true })),
  on(newsActions.getNewsItem.success, (state, { response }) => ({
    ...state,
    currentNewsItem: response.news,
    loadingNewsItem: false,
    prevNewsId: Number(response.previous_id),
    nextNewsId: Number(response.next_id)
  })),
  on(newsActions.getNewsItem.error, state => ({ ...state, loadingNewsItem: false }))
)
