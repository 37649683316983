import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ThegameService {
  constructor(private http: HttpClient) {}

  public getData(): Observable<any> {
    return this.http.get('/api/account/top_partners/game_0824')
  }
}
