import { createReducer, on } from '@ngrx/store'
import { registerPerson } from './register-person.actions'

export const registerPersonFeatureKey = 'register-person'

export interface State {
  loading: boolean
}

export const initialState: State = {
  loading: false
}

export const reducer = createReducer(
  initialState,
  on(registerPerson.start, state => ({ ...state, loading: true })),
  on(registerPerson.success, state => ({ ...state, loading: false })),
  on(registerPerson.error, state => ({ ...state, loading: false }))
)
