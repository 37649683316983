import { createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { RuPostOffice } from '../../../../../../../common/models/ru-post-office'
import { rupostLoad, rupostLoadError, rupostLoadSuccess, rupostRemove } from './rupost.actions'

export const rupostFeatureKey = 'rupost'

export interface State extends EntityState<RuPostOffice> {
  isLoading: boolean
  isLoaded: boolean
}

const selectId = (v: RuPostOffice) => v.address!.postal_code as string

const sortById = (a: RuPostOffice, b: RuPostOffice) => a.address!.postal_code!.localeCompare(b.address!.postal_code!)

export const adapter = createEntityAdapter<RuPostOffice>({
  selectId,
  sortComparer: sortById
})

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  isLoaded: false
})

export const reducer = createReducer(
  initialState,
  on(rupostLoad, state => ({ ...state, isLoading: true })),
  on(rupostLoadSuccess, (state, { data }) => adapter.setAll(data, { ...state, isLoading: false, isLoaded: true })),
  on(rupostLoadError, state => ({ ...state, isLoading: false })),
  on(rupostRemove, () => initialState)
)
