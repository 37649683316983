import { Component, EventEmitter, HostListener, inject, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { AlertService } from './alert.service'
import { AsyncPipe, NgForOf, NgIf } from '@angular/common'
import { CopyBtnComponent } from '@shared/components/copy-btn/copy-btn.component'
import { filter } from 'rxjs'
import { animate, keyframes, query, stagger, style, transition, trigger } from '@angular/animations'
import { DarkModeService } from '@shared/services/dark-mode.service'
import { DocumentScrollService } from '@shared/services/document-scroll.service'

export enum DARK_SCREENS {
  ACCOUNT = '/account',
  ACCOUNT_TOTAL_INCOME = '/account/total-income',
  ACCOUNT_TOTAL_OF_GROUP = '/account/total-of-group',
  STYLED_NAV_PROMO = '/styled-nav/promo',
  STYLED_NAV_REWARD_SYSTEM = '/styled-nav/reward-system',
  TOP = '/top',
  STYLED_NAV_PROCEDURES = '/styled-nav/procedures',
  ACCOUNT_ANALYTICS = '/account/analytics',
  ACCOUNT_ACTIVATED = '/account/activated',
  ACCOUNT_REGISTERED = '/account/registered',
  ACCOUNT_PRODUCTS = '/account/products'
}

export const appearDisappear = trigger('appearDisappear', [
  transition(':enter', [
    animate(
      300,
      keyframes([
        style({ opacity: 0, offset: 0 }),
        style({ opacity: 0.5, offset: 0.5 }),
        style({ opacity: 1, offset: 1 })
      ])
    )
  ]),
  transition(':leave', [
    animate(
      400,
      keyframes([
        style({ opacity: 1, offset: 0 }),
        style({ opacity: 0.5, offset: 0.5 }),
        style({ opacity: 0, offset: 1 })
      ])
    )
  ])
])

export const tilesAnimation = trigger('tilesAnimation', [
  // Transition from any state to any state
  transition('* => *', [
    // Initially the all cards are not visible
    query(':enter', style({ opacity: 0 }), { optional: true }),

    // Each card will appear sequentially with the delay of 0.10s
    query(
      ':enter',
      stagger('5ms', [
        animate(
          '400ms ease-in',
          keyframes([
            style({ opacity: 0, transform: 'scale(0.90)', offset: 0 }),
            style({ opacity: 0.1, transform: 'scale(0.95)', offset: 0.5 }),
            style({ opacity: 1, transform: 'scale(1)', offset: 1 })
          ])
        )
      ]),
      { optional: true }
    ),

    query(
      ':leave',
      stagger('0ms', [
        animate(
          '400ms ease-out',
          keyframes([
            style({ opacity: 1, transform: 'scale(1)', offset: 0 }),
            style({ opacity: 0.1, transform: 'scale(0.95)', offset: 0.5 }),
            style({ opacity: 0, transform: 'scale(0.90)', offset: 1 })
          ])
        )
      ]),
      { optional: true }
    )
  ])
])

@UntilDestroy()
@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  imports: [NgForOf, AsyncPipe, CopyBtnComponent, NgIf],
  standalone: true,
  animations: [tilesAnimation, appearDisappear]
})
export class AlertComponent implements OnInit, OnDestroy {
  @Input() visible = false
  @Output() hideOut: EventEmitter<void> = new EventEmitter<void>()

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.handleClose()
    }
  }

  private _darkScreens = [
    DARK_SCREENS.ACCOUNT,
    DARK_SCREENS.ACCOUNT_TOTAL_INCOME,
    DARK_SCREENS.ACCOUNT_TOTAL_OF_GROUP,
    DARK_SCREENS.STYLED_NAV_PROMO,
    DARK_SCREENS.STYLED_NAV_REWARD_SYSTEM,
    DARK_SCREENS.TOP,
    DARK_SCREENS.STYLED_NAV_PROCEDURES,
    DARK_SCREENS.ACCOUNT_ANALYTICS,
    DARK_SCREENS.ACCOUNT_ACTIVATED,
    DARK_SCREENS.ACCOUNT_REGISTERED,
    DARK_SCREENS.ACCOUNT_PRODUCTS
  ]
  public darkMode = false
  private _dark = inject(DarkModeService)
  private _documentScroll = inject(DocumentScrollService)
  private _router = inject(Router)
  public alert = inject(AlertService)

  ngOnInit(): void {
    this._dark.dark$.pipe(untilDestroyed(this)).subscribe(state => {
      this.darkMode = state
    })

    this._router.events
      .pipe(
        untilDestroyed(this),
        filter(x => x instanceof NavigationEnd)
      )
      .subscribe(event => {
        this.darkMode = this._darkScreens.some(x => (event as unknown as NavigationEnd).url.endsWith(x))
      })
  }

  handleBackdropAnimationEnd(): void {
    if (this.alert.modals$.getValue().length === 0 || !this.visible) {
      this.hideOut.emit()
    } else {
      this._documentScroll.disable()
    }
  }

  handleClose(): void {
    this.visible = false
    this.alert.closeEverything()
    this._documentScroll.enable()
  }

  ngOnDestroy() {
    this._documentScroll.enable()
  }
}
