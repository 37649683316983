import { LOCALE_ID, NgModule } from '@angular/core'
import { CommonModule, registerLocaleData } from '@angular/common'
import localeRu from '@angular/common/locales/ru'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { sdkImports } from './config/sdk-imports'
import { RouterModule, Routes } from '@angular/router'
import { AppComponent } from './app.component'
import { defaultLocale } from '../../../common/config/locale'
import { AppStoreModule } from '../store/app-store.module'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { ngxOptimizedImageLoaderProvider } from '../../../common/config/ngx-optimized-image'
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'
import { JwtInterceptor } from 'ngx-customapp-jwt'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core'
import { MY_DATE_FORMAT } from './config/date-format'
import { LuxonDateAdapter } from '@angular/material-luxon-adapter'
import { MatDialogModule } from '@angular/material/dialog'
import { godModeGuard } from './guards/god-mode.guard'
import { initJwtAuthGuard } from './guards/init-jwt-auth.guard'
import { CookieConsentComponent } from '../features/cookie-consent/cookie-consent.component'
import { MetrikaModule } from 'ng-yandex-metrika'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { Constants } from '../../../common/utils/constants/constants'
import { ScrollToTopComponent } from './ui-shell/components/scroll-to-top/scroll-to-top.component'
import { InfiniteScrollModule } from 'ngx-infinite-scroll'
import { AlertComponent } from '@shared/components/alert/alert.component'

/** All components without UI wrapper are described here */
const rootRoutes: Routes = [
  {
    path: 'skills/how-to-speak-concisely-and-to-the-point',
    loadComponent: () =>
      import(
        '../pages/skills/how-to-speak-concisely-and-to-the-point/how-to-speak-concisely-and-to-the-point.component'
      ).then(m => m.HowToSpeakConciselyAndToThePointComponent),
    canActivate: [godModeGuard, initJwtAuthGuard]
  },
  {
    path: 'skills/recruiting-how-to-sell-business',
    loadComponent: () =>
      import('../pages/skills/recruiting-how-to-sell-business/recruiting-how-to-sell-business.component').then(
        m => m.RecruitingHowToSellBusinessComponent
      ),
    canActivate: [godModeGuard, initJwtAuthGuard]
  },
  {
    path: 'skills/public-speaking',
    loadComponent: () =>
      import('../pages/skills/public-speaking/public-speaking.component').then(m => m.PublicSpeakingComponent),
    canActivate: [godModeGuard, initJwtAuthGuard]
  },
  {
    path: 'skills/woman-high-class',
    loadComponent: () =>
      import('../pages/skills/woman-high-class-page/woman-high-class.component').then(m => m.WomanHighClassComponent),
    canActivate: [godModeGuard, initJwtAuthGuard]
  },
  {
    path: 'skills/control-without-pressure',
    loadComponent: () =>
      import('../pages/skills/control-without-pressure/control-without-pressure.component').then(
        m => m.ControlWithoutPressureComponent
      ),
    canActivate: [godModeGuard, initJwtAuthGuard]
  },
  {
    path: 'oneyear',
    loadComponent: () =>
      import('../pages/anniversary-page/anniversary-page.component').then(m => m.AnniversaryPageComponent),
    canActivate: [godModeGuard, initJwtAuthGuard]
  },
  {
    path: 'twoyears',
    loadComponent: () =>
      import('../pages/second-year-page/second-year-page.component').then(m => m.SecondYearPageComponent),
    canActivate: [godModeGuard, initJwtAuthGuard]
  },
  {
    path: 'leader-camp-turkey',
    loadComponent: () =>
      import('../pages/landings/leader-camp-turkish-landing/leader-camp-turkish-landing.component').then(
        m => m.LeaderCampTurkishLandingComponent
      ),
    canActivate: [godModeGuard, initJwtAuthGuard]
  },
  {
    path: 'marathon',
    loadComponent: () =>
      import('../pages/fitness-marathon/fitness-marathon-page.component').then(m => m.FitnessMarathonPageComponent),
    canActivate: [godModeGuard, initJwtAuthGuard]
  },
  {
    path: 'business-community',
    loadChildren: () =>
      import('../pages/business-community-page/business-community.module').then(m => m.BusinessCommunityModule)
  },
  {
    path: 'thegame',
    data: { animation: 'Game' },
    loadChildren: () => import('../pages/thegame-page/thegame.module').then(m => m.ThegameModule)
  },
  {
    path: `brand`,
    loadComponent: () =>
      import('client/src/pages/product-page/components/brand-product/brand-product.component').then(
        m => m.BrandProductComponent
      )
  },
  {
    path: '',
    loadChildren: () => import('./ui-shell/ui-shell.module').then(m => m.UiShellModule),
    data: { animation: 'Shell' },
    canActivate: [godModeGuard, initJwtAuthGuard]
  }
]

registerLocaleData(localeRu)

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/translations/', '.json')
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    RouterModule.forRoot(rootRoutes, { scrollPositionRestoration: 'enabled' }),
    AppStoreModule,
    BrowserAnimationsModule,
    sdkImports,
    MatSnackBarModule,
    MatNativeDateModule,
    MatDialogModule,
    CookieConsentComponent,
    InfiniteScrollModule,
    MetrikaModule.forRoot(
      {
        id: 95_309_791,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: false
      },
      95_309_791
    ),
    TranslateModule.forRoot({
      defaultLanguage: Constants.DEFAULT_LANG,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ScrollToTopComponent,
    AlertComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: LOCALE_ID, useValue: defaultLocale },
    ngxOptimizedImageLoaderProvider,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: HttpStopInterceptor, multi: true },
    { provide: DateAdapter, useClass: LuxonDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT }
  ]
})
export class AppModule {}
