import { createFeatureSelector, createSelector } from '@ngrx/store'
import { State, featureKey } from './stock.reducer'
import { Stock } from 'common/models/stock'

export const selectStocksFeature = createFeatureSelector<State>(featureKey)

export const selectStocks = createSelector(selectStocksFeature, (stocksState): Stock[] => {
  const stocks = stocksState.stocks?.stocks ?? []
  return stocks.filter(stock => !stock.is_closed)
})

export const selectStocksForGroupOrders = createSelector(selectStocksFeature, (stocksState): Stock[] => {
  const stocks = stocksState.stocksForGroupOrders?.stocks ?? []
  return stocks.filter(stock => !stock.is_closed)
})

export const selectStocksLoading = createSelector(selectStocksFeature, s => s.stocksLoading)
