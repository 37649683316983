import { Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { EMPTY, mergeMap, of } from 'rxjs'
import { deliveryOfficeRemoveAll } from './delivery-office.actions'
import { boxberryLoad, boxberryRemove } from './boxberry/boxberry.actions'
import { kgpostLoad, kgpostRemove } from './kgpost/kgpost.actions'
import { fargoLoad, fargoRemove } from './fargo/fargo.actions'
import { cdekLoad, cdekRemove } from './cdek/cdek.actions'
import { europostLoad, europostRemove } from './europost/europost.actions'
import { haypostLoad, haypostRemove } from './haypost/haypost.actions'
import { kazpostLoad, kazpostRemove } from './kazpost/kazpost.actions'
import { dpdLoad, dpdRemove } from './dpd/dpd.actions'
import { rupostLoad, rupostRemove } from './rupost/rupost.actions'
import { deliveryIsCourierSetValue } from '../delivery-is-courier/delivery-is-courier.actions'
import { AppState } from '../../../state'
import { Store } from '@ngrx/store'
import { unreachable } from 'ngx-customapp-errors'
import { selectCartCity, selectCartCountry, selectCartDeliveryMethod } from '../../cart/cart.selectors'
import { cartSaveCity } from '../../cart/cart.actions'
import { countryHasDeliveryOffices } from '../../../../features/delivery/pipes/country-has-delivery-offices.pipe'
import { fivePostLoad, fivePostRemove } from './five-post/five-post.actions'

@Injectable()
export class DeliveryOfficeEffects {
  constructor(private actions$: Actions, private store: Store<AppState>) {}

  dispatchRemoveAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cartSaveCity),
      mergeMap(() => of(deliveryOfficeRemoveAll()))
    )
  )

  removeAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deliveryOfficeRemoveAll),
      mergeMap(() =>
        of(
          boxberryRemove(),
          cdekRemove(),
          europostRemove(),
          haypostRemove(),
          kgpostRemove(),
          fargoRemove(),
          kazpostRemove(),
          dpdRemove(),
          rupostRemove(),
          fivePostRemove()
        )
      )
    )
  )

  getOffices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deliveryIsCourierSetValue),
      concatLatestFrom(() => [
        this.store.select(selectCartDeliveryMethod),
        this.store.select(selectCartCountry),
        this.store.select(selectCartCity)
      ]),
      mergeMap(([{ data: isCourier }, method, country, city]) => {
        if (!isCourier && method && city?.id && countryHasDeliveryOffices(country, method)) {
          const city_id = city.id
          if (method.method === 'Boxberry') {
            return of(boxberryLoad({ city_id }))
          }
          if (method.method === 'Europost') {
            return of(europostLoad({ city_id }))
          }
          if (method.method === 'Haypost') {
            return of(haypostLoad({ city_id }))
          }
          if (method.method === 'KgPost') {
            return of(kgpostLoad({ city_id }))
          }
          if (method.method === 'Fargo') {
            return of(fargoLoad({ city_id }))
          }
          if (method.method === 'Kazpost') {
            return of(kazpostLoad({ city_id }))
          }
          if (method.method === 'Cdek') {
            return of(cdekLoad({ city_id }))
          }
          if (method.method === 'DPD') {
            return of(dpdLoad({ city_id }))
          }
          if (method.method === 'RuPost') {
            return of(rupostLoad({ city_id }))
          }
          if (method.method === 'FivePost') {
            return of(fivePostLoad({ city_id }))
          }
          if (method.method === 'SelfPickup') {
            return EMPTY // self-pickup office is set through backend
          }
          unreachable('Unknown delivery method ' + method)
        }
        return EMPTY
      })
    )
  )
}
