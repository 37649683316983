import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class NewPromoService {
  constructor(private http: HttpClient) {}

  public getBonuses(): Observable<any> {
    return this.http.get('/api/account/bonus_tokens')
  }
}
