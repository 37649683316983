import { Injectable } from '@angular/core'
import { FamilyCreateOneRequest } from '../../../../../../common/models/family-create-one-request'
import { Observable } from 'rxjs'
import { SuccessResponse } from '../../../../../../common/models/success-response'
import { RequestService } from '../../../../../../common/services/request.service'
import { FamilyCreateThreeRequest } from '../../../../../../common/models/family-create-three-request'

@Injectable({
  providedIn: 'root'
})
export class FamilyAgreementService {
  createOne(req: FamilyCreateOneRequest): Observable<SuccessResponse> {
    return this.request.post('/api/account/family_agreement/create_one', req)
  }

  createBoth(req: FamilyCreateThreeRequest): Observable<SuccessResponse> {
    return this.request.post('/api/account/family_agreement/create_three', req)
  }
  constructor(private request: RequestService) {}
}
