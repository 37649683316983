export const setLocalStorageStorageDeferralDate = (id: number) => {
  localStorage.setItem(`USER[${id}]`, Date.now().toString())
}

export const isNewMothBeginForDeferralDate = (milliseconds: string): boolean => {
  // Конвертируем миллисекунды в дату
  const date = new Date(milliseconds)

  // Получаем текущий месяц
  const currentMonth = new Date().getMonth()

  // Получаем текущий год
  const currentYear = new Date().getFullYear()

  // Возвращаем результат сравнения месяцев
  return currentYear > date.getFullYear() || currentMonth > date.getMonth()
}

export const getLocalStorageDeferralDate = (id: number): string => {
  const date = localStorage.getItem(`USER[${id}]`)

  return date || ''
}
