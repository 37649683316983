import { createActionGroup, props } from '@ngrx/store'
import { DateTime } from 'luxon'
import { BonusesHistory } from 'common/models/bonuses-history'

export const loadAccountBonusInfo = createActionGroup({
  source: 'Total income page get account info',
  events: {
    start: props<{ date?: DateTime }>(),
    success: props<{ response: BonusesHistory }>(),
    error: props<{ error: string }>()
  }
})

export const totalIncomeErrors = [loadAccountBonusInfo.error]
