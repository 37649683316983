import { Injectable } from '@angular/core'
import { EMPTY, Observable } from 'rxjs'
import { HttpContext } from '@angular/common/http'
import { disableJwtInterception } from 'ngx-customapp-jwt'
import { RequestService } from '../../../../../../common/services/request.service'
import { RegionsListResponse } from '../../../../../../common/models/regions-list-response'

@Injectable({
  providedIn: 'root'
})
export class RegionService {
  constructor(private request: RequestService) {}

  load(countryId: number, regionName: string): Observable<RegionsListResponse> {
    if (regionName.length < 2) {
      return EMPTY
    }

    return this.request.post('/api/delivery/regions', undefined, {
      context: new HttpContext().set(disableJwtInterception, true),
      params: {
        page: 0,
        page_size: 100,
        country_id: countryId,
        region_name: regionName
      }
    })
  }
}
