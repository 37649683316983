import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { CdekService } from './cdek.service'
import { cdekLoad, cdekLoadError, cdekLoadSuccess } from './cdek.actions'
import { catchError, map, mergeMap, of } from 'rxjs'

@Injectable()
export class CdekEffects {
  constructor(private actions$: Actions, private cdekService: CdekService) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cdekLoad),
      mergeMap(({ city_id }) =>
        this.cdekService.load(city_id).pipe(
          map(data => cdekLoadSuccess({ data })),
          catchError(error => of(cdekLoadError({ error })))
        )
      )
    )
  )
}
