import { createReducer, on } from '@ngrx/store'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { BoxberryOffice } from '../../../../../../../common/models/boxberry-office'
import { boxberryLoad, boxberryLoadError, boxberryLoadSuccess, boxberryRemove } from './boxberry.actions'

export const boxberryFeatureKey = 'boxberry'

export interface State extends EntityState<BoxberryOffice> {
  isLoading: boolean
  isLoaded: boolean
}

const selectId = (v: BoxberryOffice) => v.code as string
const sortById = (a: BoxberryOffice, b: BoxberryOffice) => a.code!.localeCompare(b.code!)

export const adapter = createEntityAdapter<BoxberryOffice>({
  selectId,
  sortComparer: sortById
})

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  isLoaded: false
})

export const reducer = createReducer(
  initialState,
  on(boxberryLoad, state => ({ ...state, isLoading: true })),
  on(boxberryLoadSuccess, (state, { data }) => adapter.setAll(data, { ...state, isLoading: false, isLoaded: true })),
  on(boxberryLoadError, state => ({ ...state, isLoading: false })),
  on(boxberryRemove, () => initialState)
)
