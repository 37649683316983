import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
  selector: '[app-link]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class]': 'getClassnames()'
  }
})
export class LinkComponent {
  @Input() isGray?: boolean
  @Input() isWhite?: boolean

  getClassnames() {
    if (this.isGray) {
      return ['gray']
    }
    if (this.isWhite) {
      return ['white']
    }
    return []
  }
}
