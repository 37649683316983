import { Actions, createEffect, ofType } from '@ngrx/effects'
import { loadBonusInfo } from './bonus-info.actions'
import { catchError, map, mergeMap, of } from 'rxjs'
import { BonusInfoService } from '../api/bonus-info.service'
import { Injectable } from '@angular/core'

@Injectable()
export class BonusInfoEffects {
  constructor(private actions$: Actions, private bonusInfoService: BonusInfoService) {}
  loadBonusInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBonusInfo.start),
      mergeMap(({ req }) =>
        this.bonusInfoService.getInfo(req).pipe(
          map(response => loadBonusInfo.success({ response })),
          catchError(e => of(loadBonusInfo.error({ error: e })))
        )
      )
    )
  )
}
