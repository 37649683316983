import { createReducer, on } from '@ngrx/store'
import { Address } from 'common/models/address'
import { addAddress, deleteAddress, getAddresses, updateAddress } from './saved-addresses.actions'
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'

export const savedAddressesFeatureKey = 'savedAdresses'

export interface State extends EntityState<Address> {
  isLoading: boolean
}

export const adapter: EntityAdapter<Address> = createEntityAdapter<Address>({
  selectId: address => address.id ?? -1
})

export const initialState: State = adapter.getInitialState({ isLoading: false, selectedAddress: null })

export const reducer = createReducer(
  initialState,
  on(getAddresses.start, state => {
    return { ...state, isLoading: true }
  }),
  on(getAddresses.error, state => {
    return { ...state, isLoading: false }
  }),
  on(getAddresses.success, updateAddress.success, addAddress.success, deleteAddress.success, (state, { addresses }) => {
    if (!addresses) {
      return state
    }
    return { ...adapter.setAll(addresses, state), isLoading: false }
  })
)
