import { createFeatureSelector, createSelector } from '@ngrx/store'
import * as fromTeam from './team.reducer'
import { Row } from 'client/src/widgets/account/team-table/team-table.component'
import { DateTime } from 'luxon'
import { phoneToTelegramLink, phoneToCellPhoneNumber, phoneToWhatsappLink } from 'common/utils/minion/to-socials-link'
import { gradeLong } from '../../../../../../common/pipes/minion/grade-long-pipe'
import { gradShort } from '../../../../../../common/pipes/minion/grade-short.pipe'
import { selectClientPartner } from 'common/store/jwt/select-client-partner'

export const selectTeamState = createFeatureSelector<fromTeam.State>(fromTeam.teamFeatureKey)

export const selectTeamNavigation = createSelector(selectTeamState, s => s.navigation)

export const selectMinions = createSelector(selectTeamState, t => t.minions)

export const selectPartnersAmount = createSelector(selectTeamState, t => t.partnersAmount)

export const selectTeamDate = createSelector(selectTeamState, t => t.date)

export const selectMinionsTableData = createSelector(
  selectMinions,
  selectTeamNavigation,
  selectClientPartner,
  selectTeamDate,
  (users, navigation, currentUser, date) => {
    const firstUserIsInUsers = currentUser?.main_user_data?.id === users[0]?.minion?.minion_data?.main_user_data?.id

    let currentUserNumber = navigation.page * navigation.size

    if (!firstUserIsInUsers) {
      currentUserNumber++
    }

    return users.map<Row>(
      ({ levelDown, descendantsLoaded, minion: { generation, minion_data: user } }, index, arr) => ({
        cells: [
          { columnName: '№', content: { num: levelDown === undefined ? currentUserNumber++ : -1 } },
          {
            columnName: 'ЛО',
            content: { personalVolume: user.bonus_system_data?.current_individual_volume ?? 0 }
          },
          { columnName: 'ГО', content: { groupVolume: user.bonus_system_data?.current_group_volume ?? 0 } },
          { columnName: 'ПР', content: { partnersAmount: user.bonus_system_data?.graph_size ?? 0 } },
          {
            columnName: 'КВ',
            content: {
              qualification: gradShort(user.bonus_system_data?.grade, user.main_user_data?.role),
              title: gradeLong(user.bonus_system_data?.grade, user.main_user_data?.role)
            }
          },
          {
            columnName: 'УР.',
            content: { level: generation, partnersAmount: user.bonus_system_data?.graph_size ?? 0 }
          },
          {
            columnName: 'ФИО.',
            content: {
              visibleId: user.main_user_data?.visible_id ?? 0,
              fullName: `${user.main_user_data?.name ?? ''} ${user.main_user_data?.surname ?? ''}`,
              links: {
                phone: phoneToCellPhoneNumber(user.main_user_data?.phone ?? ''),
                tg: phoneToTelegramLink(user.main_user_data?.phone ?? ''),
                whatsapp: phoneToWhatsappLink(user.main_user_data?.phone ?? '')
              }
            }
          },
          { columnName: 'ID', content: { visibleId: user.main_user_data?.visible_id ?? 0 } },
          {
            columnName: 'РЕГИСТРАЦИЯ',
            content: {
              registerDate: DateTime.fromObject(user.main_user_data?.register_date ?? {}).toFormat('dd.MM.yyyy')
            }
          },
          {
            columnName: 'ВОЗРАСТ',
            content: {
              age: Math.floor(Math.abs(DateTime.fromObject(user.main_user_data?.birthday ?? {}).diffNow('years').years))
            }
          },
          { columnName: 'ГОРОД', content: { city: user.main_user_data?.city ?? '' } }
        ],
        levelDown,
        userId: user.main_user_data?.id ?? -1,
        // here
        isAwaitingRoleDowngrade: user.main_user_data?.awaiting_role_downgrade,
        isCic: user.main_user_data?.is_cic,
        isOwner: firstUserIsInUsers && navigation.page === 0 && index === 0,
        descendantsLoaded,
        isNewPartner: user.main_user_data?.is_new || false,
        isTicketPurchased: user.main_user_data?.is_ticket_purchased || false,
        isNewActivation: user.main_user_data?.is_new_activation || false,
        date,
        // yes can be made faster
        showPlusNumber: !(
          levelDown !== undefined &&
          arr
            .slice(0, index)
            .reverse()
            .find(r => r.levelDown === undefined)?.minion.minion_data.main_user_data?.id ===
            currentUser?.main_user_data?.id
        ),
        isPartner: user.main_user_data?.role == 'RolePartner'
      })
    )
  }
)

export const selectFiltersDirtyStatus = createSelector(selectTeamState, s => s.areFiltersDirty)

export const selectSearchInfo = createSelector(selectTeamState, s => s.searchInfo)

export const selectTeamLoading = createSelector(selectTeamState, s => s.isLoading)
