import { createReducer, on } from '@ngrx/store'
import { getMinionById, getMinionWithdrawInfo, sendBonus, withdrawBonus } from './transfer-bonus.actions'
import { MinionFromMyTeamResponse } from 'common/models/minion-from-my-team-response'
import { FundsTransferWithdrawalResponse } from 'common/models/funds-transfer-withdrawal-response'

export const featureKey = 'transfer bonus'

export interface State {
  loading: boolean
  minionToSendFunds: MinionFromMyTeamResponse | null
  loadingUserInfo: boolean
  withdrawInfo: FundsTransferWithdrawalResponse | null
  loadingWithdrawInfo: boolean
}

export const initialState: State = {
  loading: false,
  minionToSendFunds: null,
  loadingUserInfo: false,
  withdrawInfo: null,
  loadingWithdrawInfo: false
}

export const reducer = createReducer(
  initialState,
  on(withdrawBonus.start, state => {
    return { ...state, loading: true }
  }),

  on(getMinionById.start, state => ({ ...state, loadingUserInfo: true })),
  on(getMinionById.success, state => ({ ...state, loadingUserInfo: false })),
  on(getMinionById.error, state => ({ ...state, loadingUserInfo: false })),

  on(getMinionById.success, (state, { response }) => ({ ...state, minionToSendFunds: response })),
  on(withdrawBonus.success, state => {
    return { ...state, loading: false }
  }),
  on(withdrawBonus.error, state => {
    return { ...state, loading: false }
  }),

  on(getMinionWithdrawInfo.start, state => ({ ...state, loadingWithdrawInfo: true })),
  on(getMinionWithdrawInfo.success, (state, { response }) => ({
    ...state,
    withdrawInfo: response,
    loadingWithdrawInfo: false
  })),
  on(getMinionWithdrawInfo.error, state => ({ ...state, loadingWithdrawInfo: false })),

  on(sendBonus.start, state => {
    return { ...state, loading: true }
  }),
  on(sendBonus.success, state => {
    return { ...state, loading: false }
  }),
  on(sendBonus.error, state => {
    return { ...state, loading: false }
  })
)
