import { createReducer, on } from '@ngrx/store'
import * as eventsActions from './events.actions'
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { Event } from 'common/models/event'

export const adapter: EntityAdapter<Event> = createEntityAdapter<Event>()
export const { selectAll } = adapter.getSelectors()

export const featureKey = 'activeEvents'

export interface State extends EntityState<Event> {
  loadingEvents: boolean
  currentEventItem: Event | null
}

export const initialState: State = adapter.getInitialState({
  loadingEvents: false,
  currentEventItem: null
})

export const reducer = createReducer(
  initialState,
  on(eventsActions.getActiveEventsList.start, state => ({ ...state, loadingEvents: true })),
  on(eventsActions.getActiveEventsList.success, (state, { response }) => ({
    ...adapter.setAll(response.events ?? [], state),
    loadingEvents: false
  })),
  on(eventsActions.getActiveEventsList.error, state => ({ ...state, loadingEvents: false }))
)
